<template>
  <b-form>
    <div class="overlay_retix">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="container-retix">
              <div class="row">
                <div class="col-12 align_right">
                  <a class="cta_close" @click="closePage()">
                    <img src="../assets/images/arrow_close.svg" border="0" alt="CTA Close" />
                  </a>
                </div>
              </div>
              <!-- -->
              <div class="row">
                <div class="col-12 spinner_modal">
                  <h2 style="text-align: center">{{ message }}</h2>
                  <b-spinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
export default {
  props: ["message"]
};
</script>