import { axiosRequest } from '../middlewares/axios'
import {conf} from '../config/config'

export const customerService = {
  create,
  get,
  getAllSimpleUsers,
  getByUserId,
  update,
  remove
}

// async function create({ params } : { params:any }) {
//   return await axiosRequest.putRequest(conf.API_URL + '/api/customers', params)
// }

// async function update({ params } : { params:any }) {
//   return await axiosRequest.postRequest(conf.API_URL + '/api/customers/' + params.id, params)
// }

// async function remove({ params } : { params:any }) {
//   return await axiosRequest.deleteRequest(conf.API_URL + '/api/customers/' + params.id, params)
// }

// async function getAll() {
//   return await axiosRequest.getRequest(conf.API_URL + '/api/customers')
// }

// async function get({ params } : { params:any }) {
//   return await axiosRequest.getRequest(conf.API_URL + '/api/customers/' + params.id)
// }

// async function importSheet({ params } : { params:any }) {
//   return await axiosRequest.uploadRequest(conf.API_URL + '/api/customers/' + params.customerId + '/consumptions', params.formData)
// }

// async function importPartialSheet({ params } : { params:any }) {
//   return await axiosRequest.uploadRequest(conf.API_URL + '/api/customers/' + params.customerId + '/partialConsumptions', params.formData)
// }

// async function importMissingMonths({ params } : { params:any }) {
//   return await axiosRequest.postRequest(conf.API_URL + '/api/customers/' + params.customerId + '/missingMonths', params.months)
// }

// async function getScenarios({ params } : { params:any }) {
//   return await axiosRequest.postRequest(conf.API_URL + '/api/customers/' + params.id + '/scenarios', params.simulationGoals)
// }

// async function getSimulation({ params } : { params:any }) {
//   return await axiosRequest.postRequest(conf.API_URL + '/api/customers/' + params.id + '/simulation', params.simulationParametersForm)
// }

// async function completePartialConsumptions({ params } : { params:any }) {
//   return await axiosRequest.postRequest(conf.API_URL + '/api/customers/' + params.id + '/completePartialConsumptions', params.data)
// }



async function create({ params } : { params:any }) {
  return await axiosRequest.postRequest(conf.API_URL + '/customers', params)
}

async function get({ params } : { params:any }) {
  return await axiosRequest.getRequest(conf.API_URL + '/customers/' + params.id)
}

async function getAllSimpleUsers() {
  return await axiosRequest.getRequest(conf.API_URL + '/customers/')
}

async function getByUserId({ params } : { params:any }) {
  return await axiosRequest.getRequest(conf.API_URL + '/customers/byUser/' + params.id);
}

async function update({ params } : { params:any }) {
  return await axiosRequest.putRequest(conf.API_URL + '/customers/' + params.customerId, params)
}

async function remove({ params } : { params:any }) {
  return await axiosRequest.deleteRequest(conf.API_URL + '/customers/' + params.id, params)
}