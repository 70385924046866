import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Goal from '../views/Goal.vue'
import Information from '../views/Information.vue'
import Load_curve from '../views/Load_curve.vue'
import Scenario from '../views/Scenario.vue'
import Simulation from '../views/Simulation.vue'
import Single from '../views/Single.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    props: true,
  },
  {
    path: '/goal',
    name: 'goal',
    component: Goal,
    props: true,
    meta: { requiresAdmin: true }
  },
  {
    path: '/information',
    name: 'information',
    component: Information,
    props: true,
    meta: { requiresAdmin: true }
  },
  {
    path: '/load_curve',
    name: 'load_curve',
    component: Load_curve,
    props: true,
    meta: { requiresAdmin: true }
  },
  {
    path: '/scenario',
    name: 'scenario',
    component: Scenario,
    props: true,
    meta: { requiresAdmin: true }
  },
  {
    path: '/simulation',
    name: 'simulation',
    component: Simulation,
    props: true
  },
  {
    path: '/single',
    name: 'single',
    component: Single,
    props: true,
    meta: { requiresAdmin: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    props: true
  },
];

const router = new VueRouter({
  mode: 'history',
  routes
});

function isTokenExpired(expiry) {
  const now = Math.floor(Date.now() / 1000);
  return now >= expiry;
}

function logTokenExpiration(expiry) {
  const now = Math.floor(Date.now() / 1000);
  const timeLeft = Math.max(expiry - now, 0); // Prevent negative time left
  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = timeLeft % 60;
}

router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  if (user.exp) {
    logTokenExpiration(user.exp);
  }

  if (to.name !== 'login' && !user.email) {
    next({ name: 'login' });
    return;
  }

  if (user.exp && isTokenExpired(user.exp)) {
    Vue.toasted.error('Votre session a expiré, veuillez vous reconnecter.');
    localStorage.clear();
    return;
  }

  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (user.roles && user.roles.includes('ROLE_admin')) {
      next();
    } else {
      Vue.toasted.error("Accès interdit : vous n'avez pas l'autorisation pour accéder à cette page.");
      if (from.name) {
        next(false);
      } else {
        next({ name: 'home' });
      }
    }
  } else {
    next();
  }
});

export default router;

