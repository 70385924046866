<template>
  <b-form @submit="onSubmit" @reset="onReset" v-if="show">
    <div class="overlay_retix">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="container-retix">
              <div class="row">
                <div class="col-12 align_right">
                  <a class="cta_close" @click="closePage()">
                    <img src="../assets/images/arrow_close.svg" border="0" alt="CTA Close" />
                  </a>
                </div>
              </div>
              <!-- -->
              <div class="row">
                <div class="col-12" v-if="page === 1">
                  <span class="typo_small_overlay">1/2</span>
                  <div class="row">
                    <h1 style="color:white">Saisissez le mois de référence</h1>

                    <b-form-row
                      class="row"
                      v-if="response && response.result && response.result.missingDates"
                    >
                      <b-col class="col-12">
                        <select class="form-control-select" v-model="selected">
                          <option
                            v-for="(month, index) in options"
                            :key="index"
                            :value="month.value"
                          >{{ month.text }}</option>
                        </select>
                      </b-col>
                    </b-form-row>
                  </div>
                </div>
                <div class="col-12" v-if="page === 2">
                  <span class="typo_small_overlay">1/2</span>
                  <div class="row">
                    <h1 style="color:white">Missing months</h1>

                    <b-form-row
                      class="row"
                      v-if="response && response.result && response.result.missingDates"
                    >
                      <b-col
                        v-for="(month, index) in response.result.missingDates"
                        :key="index"
                        class="col-3"
                      >
                        <label style="color:white">{{month}}</label>
                        <b-form-input
                          id="export-input-construction"
                          type="number"
                          step="any"
                          class="input_form"
                          required
                          v-on:input="updateMonth($event, index)"
                        />
                      </b-col>
                    </b-form-row>
                  </div>
                </div>
                <div class="col-12" v-if="page === 3">
                  <div class="col-12 spinner_modal">
                    <h2 style="text-align: center">Wait while calculating missing months.</h2>
                    <b-spinner />
                </div>
                </div>
              </div>
              <!-- -->
              <div class="row" v-if="showContinue">
                <div class="col-12">
                  <a class="load_more" @click="nextPage()">
                    <div class="load_more_mask">
                      <span class="txt">CONTINUER</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      showContinue: false,
      page: 1,
      selected: null,
      options: [],
      response: {}
    };
  },
  watch: {
    selected: function(newValue) {
      if (newValue) {
        this.showContinue = true;
      }
    },
    page: function(newValue) {
      this.showContinue =
        (this.page === 1 && this.selected) ||
        (this.page === 2 && this.areMonthsOK());
    },
    forms: {
      handler(newVal, oldVal) {
        this.showContinue =
          (this.page === 1 && this.selected) ||
          (this.page === 2 && this.areMonthsOK());
      },
      deep: true
    }
  },
  async mounted() {
    this.response = await this.$store.getters[
      "customer/getImportedPartialSheet"
    ];
    forms.loadCurvePartialForm.missingMonths = this.response.result.missingDates.map(
      month => {
        return { id: month, value: 0 };
      }
    );
    let index = -1;
    forms.loadCurvePartialForm.allMonths = this.response.result.allDates.map(
      month => {
        index++;
        return { value: index, text: month };
      }
    );
    this.options.push({ value: null, text: "Please select an option" });
    index = 1;
    forms.loadCurvePartialForm.allMonths.forEach(month => {
      this.options.push({ value: index, text: month.text });
      index++;
    });
  },
  methods: {
    areMonthsOK() {
      return _.every(
        forms.loadCurvePartialForm.missingMonths,
        month => month.value !== 0
      );
    },
    updateMonth(event, index) {
      forms.loadCurvePartialForm.missingMonths[index].value = event;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    closePage() {
      this.$parent.showPartial = false;
    },
    async previousPage() {
      this.page = 1;
    },
    async nextPage() {
      this.page++;

      if (this.page === 3) {
        const objectToUpdate = {
          id: forms.id,
          data: {
            months: forms.loadCurvePartialForm.missingMonths,
            month: this.options[this.selected].text
          }
        };
        await this.$store.dispatch(
          "customer/completePartialConsumptions",
          objectToUpdate
        );
        const customerResponse = this.$store.getters[
          "customer/getCompletePartialConsumptions"
        ];

        if (customerResponse.error) {
          this.errorTitle = "Get error";
          this.errorMessage = util.getErrorMessage(response.error);
          this.$bvModal.show("error-modal");
        } else {
          const objectToUpdate = {
            id: forms.id,
            simulationGoals: forms.customerForm.simulationGoalsForm
          };
          await this.$store.dispatch("customer/getScenarios", objectToUpdate);
          const customerResponse = this.$store.getters["customer/getScenarios"];

          forms.customerForm.scenariosForm = await customerResponse.scenariosView;
          this.$router.push({ name: "goal" });
        }
      }
    },
    onReset(evt) {
      evt.preventDefault();
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  }
};
</script>