import Vue from 'vue'
import Vuex from 'vuex'
import customer from './modules/customer.module'
import referential from './modules/referential.module'
import single from './modules/single.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    customer,
    referential,
    single,
  }
})
