<template>
  <div>
    <ExportForm v-if="this.page==1"/>
    <ExportPDF v-if="this.page==2" :imgSrc="imgSrc"/>
    <ModalError :message="errorMessage" :title="errorTitle"/>

    <div v-if="this.page!=2" class="content_simulation_retix">
      <div class="container-retix">
        <ul class="row template">
          <li class="col-4">
            <div class="bloc_filter">
              <b-form v-if="show" @reset="onReset" @submit="onSubmit">
                <b-form-group
                    id="simulation-button-group-back"
                    class="cta_back"
                    style="text-align:center"
                >
                  <a v-if="isAdmin" class="load_more" @click="goTo('/scenario')">
                    <div class="load_more_mask">
                    <span class="icon">
                      <svg
                          style="enable-background:new 0 0 35.2 17.4;"
                          version="1.1"
                          viewBox="0 0 35.2 17.4"
                          x="0px"
                          xml:space="preserve"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          y="0px"
                      >
                        <g>
                          <g>
                            <path
                                class="st1"
                                d="M8.2,0.3c0.4-0.4,0.9-0.4,1.3,0c0.3,0.3,0.3,0.9,0,1.3L3.1,8h31.2c0.5,0,0.9,0.4,0.9,0.9
			c0,0.5-0.4,0.9-0.9,0.9H3.1l6.4,6.4c0.3,0.4,0.3,0.9,0,1.3c-0.4,0.4-0.9,0.4-1.3,0l-8-8c-0.3-0.3-0.3-0.9,0-1.3L8.2,0.3z M8.2,0.3
			"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                      <span class="txt">Choix des scenarii</span>
                    </div>
                  </a>
                </b-form-group>
                <!-- -->
                <div
                    v-if="forms.customerForm && forms.customerForm.customerIdentityForm && forms.customerForm.customerIdentityForm.logo"
                    class="logo_client">
                  <img :src="forms.customerForm.customerIdentityForm.logo" alt="logo client"/>
                </div>
                <div class="cta_switch">
                  <input id="switch" type="checkbox" @input="changeView"/>
                  <label for="switch">Toggle</label>
                </div>

                <hr>

                <b-form-group id="simulation-slider-group-installed-power" class="bloc_form range_style">
                  <label>Puissance installée <i>(kWc)</i></label>
                  <span class="range-slider">
                  <input
                      id="simulation-slider-installed-power"
                      v-model.number="slidderIndexes.installedPowerIndex"
                      :disabled="updating"
                      :max="9"
                      :min="0"
                      :step="1"
                      type="range"
                      @change="slidderChange ()"
                      @input="slidderInput ()"/>

                  <span class="slider-container">
                    <span class="bar">
                      <span :style="{width: Math.round(slidderIndexes.installedPowerIndex * 100 / 9) + '%'}"></span>
                    </span>
                    <span :style="{left: Math.round(slidderIndexes.installedPowerIndex * 100 / 9) + '%'}"
                          class="bar-btn">
                      <b-spinner v-if="updating" small/>
                      <span v-else>{{
                          forms.simulationParametersSets.installedPowerSet[slidderIndexes.installedPowerIndex]
                        }}</span>
                    </span>
                  </span>
                </span>

                  <ul class="legende_slider">
                    <li v-for="(value, index) in forms.simulationParametersSets.installedPowerSet" :key="index">
                      {{ value }}
                    </li>
                  </ul>
                </b-form-group>

                <hr>

                <b-form-group id="simulation-slider-group-battery-capacity" class="bloc_form range_style">
                  <label>Capacité batterie <i>(kWh)</i></label>
                  <span class="range-slider">
                  <input
                      id="simulation-slider-battery-capacity"
                      v-model.number="slidderIndexes.batteryCapacityIndex"
                      :disabled="updating"
                      :max="9"
                      :min="0"
                      :step="1"
                      type="range"
                      @change="slidderChange ()"
                      @input="slidderInput ()"/>

                  <span class="slider-container">
                    <span class="bar">
                      <span :style="{width: Math.round(slidderIndexes.batteryCapacityIndex * 100 / 9) + '%'}"></span>
                    </span>
                    <span :style="{left: Math.round(slidderIndexes.batteryCapacityIndex * 100 / 9) + '%'}"
                          class="bar-btn">
                      <b-spinner v-if="updating" small/>
                      <span v-else>{{
                          forms.simulationParametersSets.batteryCapacitySet[slidderIndexes.batteryCapacityIndex]
                        }}</span>
                    </span>
                  </span>
                </span>

                  <ul class="legende_slider">
                    <li v-for="(value, index) in forms.simulationParametersSets.batteryCapacitySet" :key="index">
                      {{ value }}
                    </li>
                  </ul>
                </b-form-group>

                <hr>

                <b-form-group id="simulation-slider-group-maximum-power" class="bloc_form range_style">
                  <label>Puissance d'injection réseau maximale <i>(kVA)</i></label>
                  <span class="range-slider">
                  <input
                      id="simulation-slider-maximum-power"
                      v-model.number="slidderIndexes.injectionPowerIndex"
                      :disabled="updating"
                      :max="9"
                      :min="0"
                      :step="1"
                      type="range"
                      @change="slidderChange ()"
                      @input="slidderInput ()"/>

                  <span class="slider-container">
                    <span class="bar">
                      <span :style="{width: Math.round(slidderIndexes.injectionPowerIndex * 100 / 9) + '%'}"></span>
                    </span>
                    <span :style="{left: Math.round(slidderIndexes.injectionPowerIndex * 100 / 9) + '%'}"
                          class="bar-btn">
                      <b-spinner v-if="updating" small/>
                      <span v-else>{{
                          forms.simulationParametersSets.injectionPowerSet[slidderIndexes.injectionPowerIndex]
                        }}</span>
                    </span>
                  </span>
                </span>

                  <ul class="legende_slider">
                    <li v-for="(value, index) in forms.simulationParametersSets.injectionPowerSet" :key="index">
                      {{ value }}
                    </li>
                  </ul>
                </b-form-group>

                <hr>

                <b-form-group id="simulation-slider-group-trigger-threshold" class="bloc_form range_style">
                  <label>Seuil de déclenchement batterie en <i>kVA</i></label>
                  <span class="range-slider">
                  <input
                      id="simulation-slider-trigger-threshold"
                      v-model.number="slidderIndexes.batteryTriggerThresholdIndex"
                      :disabled="updating"
                      :max="9"
                      :min="0"
                      :step="1"
                      type="range"
                      @change="slidderChange ()"
                      @input="slidderInput ()"/>

                  <span class="slider-container">
                    <span class="bar">
                      <span
                          :style="{width: Math.round(slidderIndexes.batteryTriggerThresholdIndex * 100 / 9) + '%'}"></span>
                    </span>
                    <span :style="{left: Math.round(slidderIndexes.batteryTriggerThresholdIndex * 100 / 9) + '%'}"
                          class="bar-btn">
                      <b-spinner v-if="updating" small/>
                      <span v-else>{{
                          forms.simulationParametersSets.batteryTriggerThresholdSet[slidderIndexes.batteryTriggerThresholdIndex]
                        }}</span>
                    </span>
                  </span>
                </span>

                  <ul class="legende_slider">
                    <li v-for="(value, index) in forms.simulationParametersSets.batteryTriggerThresholdSet"
                        :key="index">
                      {{ value }}
                    </li>
                  </ul>
                </b-form-group>

                <hr>

                <div class="cta_export" v-if="isAdmin">
                  <div class="load_more" @click="page=1">

                    <div class="load_more_mask">
										<span class="icon">
                      <svg style="enable-background:new 0 0 383.8 356.3;" version="1.1"
                           viewBox="0 0 383.8 356.3"
                           x="0px"
                           xml:space="preserve"
                           xmlns="http://www.w3.org/2000/svg"
                           xmlns:xlink="http://www.w3.org/1999/xlink"
                           y="0px">
                        <g>
                          <g>
                            <g>
                              <path class="st1" d="M369.2,173.3c-6,0-10.8,4.8-10.8,10.8v98.2c0,26.7-21.8,48.4-48.4,48.4H73c-26.7,0-48.4-21.8-48.4-48.4
                                v-99.8c0-6-4.8-10.8-10.8-10.8S3,176.5,3,182.5v99.8c0,38.6,31.4,70,70,70h237c38.6,0,70-31.4,70-70v-98.2
                                C380,178.2,375.2,173.3,369.2,173.3L369.2,173.3z M369.2,173.3"/>
                              <path class="st1" d="M310,355.4H73c-40.3,0-73-32.7-73-73v-99.8c0-7.6,6.2-13.8,13.8-13.8s13.8,6.2,13.8,13.8v99.8
                                c0,25,20.4,45.4,45.4,45.4h237c25,0,45.4-20.4,45.4-45.4v-98.2c0-7.6,6.2-13.8,13.8-13.8s13.8,6.2,13.8,13.8v98.2
                                C383,322.6,350.2,355.4,310,355.4z M13.8,174.7c-4.4,0-7.8,3.4-7.8,7.8v99.8c0,36.9,30.1,67,67,67h237c36.9,0,67-30.1,67-67
                                v-98.2c0-4.3-3.5-7.8-7.8-7.8c-4.4,0-7.8,3.4-7.8,7.8v98.2c0,28.3-23.1,51.4-51.4,51.4H73c-28.3,0-51.4-23.1-51.4-51.4v-99.8
                                C21.6,178.1,18.2,174.7,13.8,174.7z"/>
                            </g>
                          </g>
                          <g>
                            <path class="st1" d="M183.9,266.6c2.1,2.1,4.9,3.2,7.6,3.2c2.7,0,5.5-1,7.6-3.2l68.6-68.6c4.2-4.2,4.2-11,0-15.3
                              c-4.2-4.2-11-4.2-15.3,0l-50.2,50.2V13.8c0-6-4.8-10.8-10.8-10.8s-10.8,4.8-10.8,10.8v219.1l-50.2-50.2c-4.2-4.2-11-4.2-15.3,0
                              c-4.2,4.2-4.2,11,0,15.3L183.9,266.6z M183.9,266.6"/>
                            <path class="st1" d="M191.5,272.8c-3.6,0-7.1-1.5-9.7-4.1l0,0L113,200.1c-5.4-5.4-5.4-14.1,0-19.5c5.4-5.4,14.1-5.4,19.5,0
                              l45.1,45.1V13.8c0-7.6,6.2-13.8,13.8-13.8c7.6,0,13.8,6.2,13.8,13.8v211.9l45-45.1c2.6-2.6,6.1-4.1,9.8-4.1s7.1,1.4,9.8,4.1
                              c5.4,5.4,5.4,14.1,0,19.5l-68.6,68.6C198.7,271.3,195.2,272.8,191.5,272.8z M186,264.5c1.5,1.5,3.5,2.3,5.5,2.3
                              c2.1,0,4-0.8,5.4-2.3l68.7-68.7c3.1-3.1,3.1-7.9,0-11c-1.5-1.5-3.4-2.3-5.5-2.3s-4,0.8-5.5,2.3l-55.3,55.4V13.8
                              c0-4.4-3.4-7.8-7.8-7.8c-4.4,0-7.8,3.4-7.8,7.8v226.4l-55.4-55.4c-3-3-8-3-11,0c-3.1,3.1-3.1,7.9,0,11L186,264.5z"/>
                          </g>
                        </g>
                      </svg>
										</span>
                      <span class="txt">Exporter synthèse</span>
                    </div>
                  </div>
                </div>

                  <hr>

                  <div class="cta_export">
                      <div class="load_more" @click="downloadInjectionCurve()">

                        <div class="load_more_mask">
                            <span class="icon">
                          <svg style="enable-background:new 0 0 383.8 356.3;" version="1.1"
                               viewBox="0 0 383.8 356.3"
                               x="0px"
                               xml:space="preserve"
                               xmlns="http://www.w3.org/2000/svg"
                               xmlns:xlink="http://www.w3.org/1999/xlink"
                               y="0px">
                            <g>
                              <g>
                                <g>
                                  <path class="st1" d="M369.2,173.3c-6,0-10.8,4.8-10.8,10.8v98.2c0,26.7-21.8,48.4-48.4,48.4H73c-26.7,0-48.4-21.8-48.4-48.4
                                    v-99.8c0-6-4.8-10.8-10.8-10.8S3,176.5,3,182.5v99.8c0,38.6,31.4,70,70,70h237c38.6,0,70-31.4,70-70v-98.2
                                    C380,178.2,375.2,173.3,369.2,173.3L369.2,173.3z M369.2,173.3"/>
                                  <path class="st1" d="M310,355.4H73c-40.3,0-73-32.7-73-73v-99.8c0-7.6,6.2-13.8,13.8-13.8s13.8,6.2,13.8,13.8v99.8
                                    c0,25,20.4,45.4,45.4,45.4h237c25,0,45.4-20.4,45.4-45.4v-98.2c0-7.6,6.2-13.8,13.8-13.8s13.8,6.2,13.8,13.8v98.2
                                    C383,322.6,350.2,355.4,310,355.4z M13.8,174.7c-4.4,0-7.8,3.4-7.8,7.8v99.8c0,36.9,30.1,67,67,67h237c36.9,0,67-30.1,67-67
                                    v-98.2c0-4.3-3.5-7.8-7.8-7.8c-4.4,0-7.8,3.4-7.8,7.8v98.2c0,28.3-23.1,51.4-51.4,51.4H73c-28.3,0-51.4-23.1-51.4-51.4v-99.8
                                    C21.6,178.1,18.2,174.7,13.8,174.7z"/>
                                </g>
                              </g>
                              <g>
                                <path class="st1" d="M183.9,266.6c2.1,2.1,4.9,3.2,7.6,3.2c2.7,0,5.5-1,7.6-3.2l68.6-68.6c4.2-4.2,4.2-11,0-15.3
                                  c-4.2-4.2-11-4.2-15.3,0l-50.2,50.2V13.8c0-6-4.8-10.8-10.8-10.8s-10.8,4.8-10.8,10.8v219.1l-50.2-50.2c-4.2-4.2-11-4.2-15.3,0
                                  c-4.2,4.2-4.2,11,0,15.3L183.9,266.6z M183.9,266.6"/>
                                <path class="st1" d="M191.5,272.8c-3.6,0-7.1-1.5-9.7-4.1l0,0L113,200.1c-5.4-5.4-5.4-14.1,0-19.5c5.4-5.4,14.1-5.4,19.5,0
                                  l45.1,45.1V13.8c0-7.6,6.2-13.8,13.8-13.8c7.6,0,13.8,6.2,13.8,13.8v211.9l45-45.1c2.6-2.6,6.1-4.1,9.8-4.1s7.1,1.4,9.8,4.1
                                  c5.4,5.4,5.4,14.1,0,19.5l-68.6,68.6C198.7,271.3,195.2,272.8,191.5,272.8z M186,264.5c1.5,1.5,3.5,2.3,5.5,2.3
                                  c2.1,0,4-0.8,5.4-2.3l68.7-68.7c3.1-3.1,3.1-7.9,0-11c-1.5-1.5-3.4-2.3-5.5-2.3s-4,0.8-5.5,2.3l-55.3,55.4V13.8
                                  c0-4.4-3.4-7.8-7.8-7.8c-4.4,0-7.8,3.4-7.8,7.8v226.4l-55.4-55.4c-3-3-8-3-11,0c-3.1,3.1-3.1,7.9,0,11L186,264.5z"/>
                              </g>
                            </g>
                          </svg>
                                            </span>
                          <span class="txt">Exporter courbe d'injection</span>
                        </div>
                      </div>
                  </div>
                  <b-form-group id="injection-curve-export-params" class="bloc_form">
                      <label>Séparateur de décimal</label>
                      <b-form-select
                              id="injection-curve-separator-input"
                              v-model="forms.injectionCurveOptionsForm.decimalSeparator"
                              :options="decimalSeparators"
                              class="input_form"
                              required
                      />
                  </b-form-group>

              </b-form>
            </div>
          </li>
          <li class="col-8">
            <div class="content_right_simulation">
              <MonthlySimulationForm :class='monthlyView ? "" : "hack_export_position"'/>
              <YearlySimulationForm :class='monthlyView ? "hack_export_position" : ""'/>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import MonthlySimulationForm from "../components/MonthlySimulation_form"
import YearlySimulationForm from "../components/YearlySimulation_form"
import ExportForm from "../components/Export_form"
import ExportPDF from "../components/Export_PDF"
import ModalError from "../components/ModalError"
import util from "../utils/util"
import customerUtil from "../utils/customer"
import validation from "@/utils/validation";

export default {
  name: "App",
  components: {
    MonthlySimulationForm,
    YearlySimulationForm,
    ExportForm,
    ExportPDF,
    ModalError
  },
  data() {
    return {
      show: true,
      monthlyView: true,
      viewStatus: "monthly",
      page: 0,
      errorMessage: '',
      errorTitle: '',
      updating: false,
      imgSrc: '',
      decimalSeparators: ["virgule", "point"],
      slidderIndexes: {
        installedPowerIndex: 0,
        batteryCapacityIndex: 0,
        injectionPowerIndex: 9,
        batteryTriggerThresholdIndex: 0
      }
    };
  },
  props: ['slidderIndexesProp'],
  mounted() {
      forms.injectionCurveOptionsForm.decimalSeparator = "virgule";
    if (!this.slidderIndexesProp) {
      this.slidderIndexes = JSON.parse(window.localStorage.getItem('slidderIndexes')) || {
        installedPowerIndex: 0,
        batteryCapacityIndex: 0,
        injectionPowerIndex: 9,
        batteryTriggerThresholdIndex: 0
      };
    } else {
      this.slidderIndexes = this.slidderIndexesProp;
    }
    window.localStorage.setItem('slidderIndexes', JSON.stringify(this.slidderIndexes));
  },
  computed: {
    isAdmin() {
      const user = JSON.parse(localStorage.getItem('user'));
      return user && user.roles.includes('ROLE_admin');
    },
  },
  methods: {
    slidderInput() {
      forms.simulationParametersForm.installedPower = forms.simulationParametersSets.installedPowerSet[this.slidderIndexes.installedPowerIndex]
      forms.simulationParametersForm.batteryCapacity = forms.simulationParametersSets.batteryCapacitySet[this.slidderIndexes.batteryCapacityIndex]
      forms.simulationParametersForm.maximumNetworkInjectionPower = forms.simulationParametersSets.injectionPowerSet[this.slidderIndexes.injectionPowerIndex]
      forms.simulationParametersForm.batteryTriggerThreshold = forms.simulationParametersSets.batteryTriggerThresholdSet[this.slidderIndexes.batteryTriggerThresholdIndex]

      this.$forceUpdate()
    },
    async slidderChange() {
      this.updating = true

      const objectToUpdate = {
        customerId: forms.id,
        installedPower: forms.simulationParametersForm.installedPower,
        batteryCapacity: forms.simulationParametersForm.batteryCapacity,
        maximumNetworkInjectionPower: forms.simulationParametersForm.maximumNetworkInjectionPower,
        batteryTriggerThreshold: forms.simulationParametersForm.batteryTriggerThreshold
      }

      await this.$store.dispatch('customer/getSimulation', objectToUpdate)
      const customerResponse = this.$store.getters['customer/getSimulation']

      if (customerResponse.error) {
        this.errorTitle = "Simulation error"
        this.errorMessage = util.getErrorMessage(customerResponse.error)
        this.$bvModal.show('error-modal')
      } else {
        forms.customerForm.simulationForm = customerResponse.simulation
        this.$forceUpdate()
      }

      this.updating = false
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onReset(evt) {
      evt.preventDefault();
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    changeView() {
      if (document.getElementById("switch").content == "Vue mensuelle") {
        this.viewStatus = "yearly";
      } else this.viewStatus = "monthly";

      this.monthlyView = !this.monthlyView;
    },
      async convertDataToCSV(data, separator) {
          let csvContent = "data:text/csv;charset=utf-8,";

          const formatNumber = (number) => {
              if (separator === ",") {
                  return (number*6*1000).toString().replace(/\./g, ",");
              } else {
                  return number*6*1000;
              }
          };

          const formatDate = (dateTime) => {
              const [date, time] = dateTime.split("T");
              const [year, month, day] = date.split("-");
              return `${day}/${month}/${year}`;
          };

          for (const [dateTime, value] of Object.entries(data)) {
              const formattedValue = formatNumber(value);
              const formattedDate = formatDate(dateTime);
              const [_, time] = dateTime.split("T");
              csvContent += `${formattedDate};${time};${formattedValue};\n`;
          }

          const clientName = forms.customerForm.customerIdentityForm.companyName.replace(/ /g, "_");
          const encodedUri = encodeURI(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", `courbe-injection-${clientName}_${forms.simulationParametersForm.installedPower}kWc_${forms.simulationParametersForm.batteryCapacity}kWh.csv`);
          document.body.appendChild(link);
          link.click();
      },


      async downloadInjectionCurve() {
      const objectToUpdate = {
        customerId: forms.id,
        installedPower: forms.simulationParametersForm.installedPower,
        batteryCapacity: forms.simulationParametersForm.batteryCapacity,
        maximumNetworkInjectionPower: forms.simulationParametersForm.maximumNetworkInjectionPower,
        batteryTriggerThreshold: forms.simulationParametersForm.batteryTriggerThreshold,
        decimalSeparator: ""
      }
      try {
        forms.injectionCurveOptionsForm.decimalSeparator === "point" ? objectToUpdate.decimalSeparator = "." : objectToUpdate.decimalSeparator = ",";
        await this.$store.dispatch('customer/getInjectionCurve', objectToUpdate)
        const InjectionCurve = this.$store.getters['customer/getInjectionCurve']
          this.convertDataToCSV(InjectionCurve, objectToUpdate.decimalSeparator)
        this.$forceUpdate()
      } catch (e) {
        console.log(e)
      }
    },
      goTo: function (route) {
          if (
              route === "/load_curve" &&
              validation.isLoadCurveAnalyseValid(this.forms.customerForm.consumptionCurveForm)
          ) {
              this.$router.push(route);
          }
          if (route === "/scenario" && forms.customerForm.scenariosForm) {
              this.$router.push(route);
          }
          if (route === "/goal" && forms.customerForm.consumptionCurveForm.annualConsumption) {
              this.$router.push(route);
          }
          if (route === "/information") {
              this.$router.push(route);
          }
      }
  }
};
</script>