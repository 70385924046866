<template>
  <b-form @submit="onSubmit" v-if="show">
    <div class="overlay_retix">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="container-retix">
              <div class="row">
                <div class="col-12 align_right">
                  <a @click="closePage();goToWithoutCheck('/simulation')" class="cta_close">
                    <img src="../assets/images/arrow_close.svg" border="0" alt="CTA Close" />
                  </a>
                </div>
              </div>

              <div class="row">
                <h1 style="color:white">Central de production</h1>

                <b-form-row class="row">
                  <b-col class="col">
                    <label style="color:white">Dégradation annuelle en %</label>
                    <b-form-input
                      id="export-input-construction"
                      v-model="form.exportForm.degradationAnnuelle.value"
                      type="number"
                      step="any"
                      class="input_form"
                      required
                    />
                  </b-col>
                  <b-col class="col">
                    <label style="color:white">Coût Wc en €/Wc</label>
                    <b-form-input
                      id="export-input-storage-cost"
                      v-model="form.exportForm.coutWc.value"
                      type="number"
                      step="any"
                      class="input_form"
                      required
                    />
                  </b-col>
                </b-form-row>
              </div>

              <div class="row">
                <h1 style="color:white;padding-top:50px">Exploitation</h1>

                <b-form-row class="row">
                  <b-col class="col">
                    <label style="color:white">Maintenance préventive en €</label>
                    <b-form-input
                      id="export-input-inverter-warranty"
                      v-model="form.exportForm.maintenancePreventive.value"
                      type="number"
                      step="any"
                      class="input_form"
                      required
                    />
                  </b-col>
                  <b-col class="col">
                    <label style="color:white">Garantie onduleurs en année</label>
                    <b-form-input
                      id="export-input-insurance"
                      v-model="form.exportForm.garantieOndulaires.value"
                      type="number"
                      step="any"
                      class="input_form"
                      required
                    />
                  </b-col>
                  <b-col class="col">
                    <label style="color:white">Assurance en €</label>
                    <b-form-input
                      id="export-input-annual-inverter-provision"
                      v-model="form.exportForm.assurance.value"
                      type="number"
                      step="any"
                      class="input_form"
                      required
                    />
                  </b-col>
                </b-form-row>
                <b-form-row class="row">
                  <b-col class="col">
                    <label style="color:white">Abonnement DATA en €</label>
                    <b-form-input
                      id="export-input-preventive-maintenance"
                      v-model="form.exportForm.abonnementDATA.value"
                      type="number"
                      step="any"
                      class="input_form"
                      required
                    />
                  </b-col>
                  <b-col class="col">
                    <label style="color:white">Provision onduleurs en €/Wc</label>
                    <b-form-input
                      id="export-input-subscription-data"
                      v-model="form.exportForm.provisionOnduleurs.value"
                      type="number"
                      step="any"
                      class="input_form"
                      required
                    />
                  </b-col>
                </b-form-row>
              </div>

              <div class="row">
                <h1 style="color:white;padding-top:50px">Financement</h1>

                <b-form-row class="row">
                  <b-col class="col">
                    <label style="color:white">Apport en %</label>
                    <b-form-input
                      id="export-input-inverter-contribution"
                      v-model="form.exportForm.apport.value"
                      type="number"
                      step="any"
                      class="input_form"
                      required
                    />
                  </b-col>
                  <b-col class="col">
                    <label style="color:white">Taux d'intérêt en %</label>
                    <b-form-input
                      id="export-input-rate"
                      v-model="form.exportForm.taux.value"
                      type="number"
                      step="any"
                      class="input_form"
                      required
                    />
                  </b-col>
                  <b-col class="col">
                    <label style="color:white">Durée en année</label>
                    <b-form-input
                      id="export-input-duration-in-years"
                      v-model="form.exportForm.duree.value"
                      type="number"
                      step="any"
                      class="input_form"
                      required
                    />
                  </b-col>
                </b-form-row>
                <b-form-row class="row">
                  <b-col class="col-4">
                    <label style="color:white">Frais de dossier en €</label>
                    <b-form-input
                      id="export-input-fees"
                      v-model="form.exportForm.fraisDeDossier.value"
                      type="number"
                      step="any"
                      class="input_form"
                      required
                    />
                  </b-col>
                  <b-col class="col-4">
                    <label style="color:white">Différé en mois</label>
                    <b-form-input
                      id="export-input-deferred"
                      v-model="form.exportForm.differe.value"
                      type="number"
                      step="any"
                      class="input_form"
                      required
                    />
                  </b-col>
                </b-form-row>
              </div>

              <div class="row">
                <h1 style="color:white;padding-top:50px">Scénario</h1>

                <b-form-row class="row">
                  <b-col class="col">
                    <label style="color:white">Inflation en %</label>
                    <b-form-input
                      id="export-input-inflation"
                      v-model="form.exportForm.inflation.value"
                      type="number"
                      step="any"
                      class="input_form"
                      required
                    />
                  </b-col>
                  <b-col class="col">
                    <label style="color:white">Inflation énergie en %</label>
                    <b-form-input
                      id="export-input-energy-inflation"
                      v-model="form.exportForm.inflationEnergie.value"
                      type="number"
                      step="any"
                      class="input_form"
                      required
                    />
                  </b-col>
                  <b-col class="col">
                    <label style="color:white">Durée amortissement en année</label>
                    <b-form-input
                      id="export-input-amortization"
                      v-model="form.exportForm.dureeAmortissement.value"
                      type="number"
                      step="any"
                      class="input_form"
                      required
                    />
                  </b-col>
                </b-form-row>
                <b-form-row class="row">
                  <b-col class="col">
                    <label style="color:white">Taux IS en %</label>
                    <b-form-input
                      id="export-input-is-rate"
                      v-model="form.exportForm.tauxIS.value"
                      type="number"
                      step="any"
                      class="input_form"
                      required
                    />
                  </b-col>
                </b-form-row>
              </div>

              <div class="row">
                <b-form-row class="row">
                  <b-col class="col">
                    <div class="cta_export">
                      <div class="load_more" @click="thisFileUpload()">
                        <div class="load_more_mask">
                          <span class="icon">
                            <img
                              class="upload-picture margin_right_20"
                              src="@/assets/images/upload.svg"
                            />
                          </span>
                          <span class="txt">IMPORTER LE BP</span>
                        </div>
                      </div>
                    </div>
                  </b-col>
                  <b-col class="col" v-if="importedFiles && importedFiles.length">
                    <div class="cta_export">
                      <div class="load_more" @click="downloadFile()">
                        <div class="load_more_mask">
                          <span class="icon margin_right_20">
                            <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 383.8 356.3"
                              style="enable-background:new 0 0 383.8 356.3;"
                              xml:space="preserve"
                            >
                              <g>
                                <g>
                                  <g>
                                    <path
                                      class="st1"
                                      d="M369.2,173.3c-6,0-10.8,4.8-10.8,10.8v98.2c0,26.7-21.8,48.4-48.4,48.4H73c-26.7,0-48.4-21.8-48.4-48.4
                                v-99.8c0-6-4.8-10.8-10.8-10.8S3,176.5,3,182.5v99.8c0,38.6,31.4,70,70,70h237c38.6,0,70-31.4,70-70v-98.2
                                C380,178.2,375.2,173.3,369.2,173.3L369.2,173.3z M369.2,173.3"
                                    />
                                    <path
                                      class="st1"
                                      d="M310,355.4H73c-40.3,0-73-32.7-73-73v-99.8c0-7.6,6.2-13.8,13.8-13.8s13.8,6.2,13.8,13.8v99.8
                                c0,25,20.4,45.4,45.4,45.4h237c25,0,45.4-20.4,45.4-45.4v-98.2c0-7.6,6.2-13.8,13.8-13.8s13.8,6.2,13.8,13.8v98.2
                                C383,322.6,350.2,355.4,310,355.4z M13.8,174.7c-4.4,0-7.8,3.4-7.8,7.8v99.8c0,36.9,30.1,67,67,67h237c36.9,0,67-30.1,67-67
                                v-98.2c0-4.3-3.5-7.8-7.8-7.8c-4.4,0-7.8,3.4-7.8,7.8v98.2c0,28.3-23.1,51.4-51.4,51.4H73c-28.3,0-51.4-23.1-51.4-51.4v-99.8
                                C21.6,178.1,18.2,174.7,13.8,174.7z"
                                    />
                                  </g>
                                </g>
                                <g>
                                  <path
                                    class="st1"
                                    d="M183.9,266.6c2.1,2.1,4.9,3.2,7.6,3.2c2.7,0,5.5-1,7.6-3.2l68.6-68.6c4.2-4.2,4.2-11,0-15.3
                              c-4.2-4.2-11-4.2-15.3,0l-50.2,50.2V13.8c0-6-4.8-10.8-10.8-10.8s-10.8,4.8-10.8,10.8v219.1l-50.2-50.2c-4.2-4.2-11-4.2-15.3,0
                              c-4.2,4.2-4.2,11,0,15.3L183.9,266.6z M183.9,266.6"
                                  />
                                  <path
                                    class="st1"
                                    d="M191.5,272.8c-3.6,0-7.1-1.5-9.7-4.1l0,0L113,200.1c-5.4-5.4-5.4-14.1,0-19.5c5.4-5.4,14.1-5.4,19.5,0
                              l45.1,45.1V13.8c0-7.6,6.2-13.8,13.8-13.8c7.6,0,13.8,6.2,13.8,13.8v211.9l45-45.1c2.6-2.6,6.1-4.1,9.8-4.1s7.1,1.4,9.8,4.1
                              c5.4,5.4,5.4,14.1,0,19.5l-68.6,68.6C198.7,271.3,195.2,272.8,191.5,272.8z M186,264.5c1.5,1.5,3.5,2.3,5.5,2.3
                              c2.1,0,4-0.8,5.4-2.3l68.7-68.7c3.1-3.1,3.1-7.9,0-11c-1.5-1.5-3.4-2.3-5.5-2.3s-4,0.8-5.5,2.3l-55.3,55.4V13.8
                              c0-4.4-3.4-7.8-7.8-7.8c-4.4,0-7.8,3.4-7.8,7.8v226.4l-55.4-55.4c-3-3-8-3-11,0c-3.1,3.1-3.1,7.9,0,11L186,264.5z"
                                  />
                                </g>
                              </g>
                            </svg>
                          </span>
                          <span class="txt">TELECHARGER LE BP</span>
                        </div>
                      </div>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="cta_export">
                      <div class="load_more" style="width:300px" @click="loadSynthese()">
                        <div class="load_more_mask" style="width:300px">
                          <span class="icon">
                            <img
                              class="upload-picture margin_right_20"
                              src="@/assets/images/display.svg"
                            />
                          </span>
                          <span class="txt">AFFICHER LA SYNTHESE</span>
                        </div>
                      </div>
                    </div>
                  </b-col>
                  <b-col class="col">
                    <div class="cta_export">
                      <div class="load_more" @click="reset()">
                        <div class="load_more_mask">
                          <span class="icon">
                            <img
                              class="upload-picture margin_right_20"
                              src="@/assets/images/reset.svg"
                            />
                          </span>
                          <span class="txt">Reset</span>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-form-row>>
              </div>
            </div>
          </div>
        </div>
      </div>
      <input
        style="display:none"
        class="cta_export"
        type="file"
        id="button-upload"
        name="file"
        ref="file"
        @change="loadFile"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.ms-excel.sheet.macroEnabled.12"
      />
    </div>
  </b-form>
</template>

<script>
import util from "../utils/util";
import * as ExcelJS from "exceljs/dist/es5/exceljs.browser"; //"exceljs/dist/exceljs.min.js"
const fs = require("fs");
import _ from "lodash"

export default {
  data() {
    return {
      show: true,
      importedFiles: null,
      form: {
        exportForm: {
          degradationAnnuelle: {
            value: "",
            position: "C51"
          },
          coutWc: {
            value: "",
            position: "C52"
          },
          maintenancePreventive: {
            value: "",
            position: "C57"
          },
          garantieOndulaires: {
            value: "",
            position: "C58"
          },
          assurance: {
            value: "",
            position: "C59"
          },
          abonnementDATA: {
            value: "",
            position: "C60"
          },
          provisionOnduleurs: {
            value: "",
            position: "C61"
          },
          apport: {
            value: "",
            position: "J48"
          },
          duree: {
            value: "",
            position: "J49"
          },
          taux: {
            value: "",
            position: "J50"
          },
          fraisDeDossier: {
            value: "",
            position: "J52"
          },
          differe: {
            value: "",
            position: "J53"
          },
          inflation: {
            value: "",
            position: "J60"
          },
          inflationEnergie: {
            value: "",
            position: "J61"
          },
          dureeAmortissement: {
            value: "",
            position: "J62"
          },
          tauxIS: {
            value: "",
            position: "J63"
          }
        },
        simulationForm: {
          powerPeak: {
            value: "",
            position: "C47"
          },
          producible: {
            value: "",
            position: "C50"
          },
          initialSellPrice: {
            value: "",
            position: "J57"
          },
          surplusValorization: {
            value: "",
            position: "J58"
          }
        }
      }
    };
  },
  mounted() {
    this.readCookie();
  },
  watch: {
    'form.exportForm': {
      handler(newVal, oldVal) {
        this.createCookie();
      },
      deep: true
    }
  },
  methods: {
    thisFileUpload(event) {
      document.getElementById("button-upload").click();
    },
    createCookie() {
      const key = "RETIX-Customer" + forms.id;
      const value = JSON.stringify(this.form.exportForm);

      util.createCookie(key, value);
    },
    readCookie() {
      const key = "RETIX-Customer" + forms.id;
      const value = util.readCookie(key);

      if (value) {
        this.form.exportForm = JSON.parse(value);
      }
    },
    loadBP() {
      const charts = document.getElementsByClassName("chartjs-render-monitor");
      const chart = charts[0];
      const imgSrc = chart.toDataURL();

      this.$parent.imgSrc = imgSrc;
      this.$parent.page = 2;
    },
    loadSynthese() {
      this.$router.push("/single");
    },
    loadFile(event) {
      const self = this;
      this.importedFiles = event.target.files || event.dataTransfer.files;
    },
    downloadFile() {
      const self = this;
      const files = this.importedFiles;
      if (files.length) {
        var workbook = new ExcelJS.Workbook();

        const templateExcelFile = files[0];

        const reader = new FileReader();
        reader.readAsArrayBuffer(templateExcelFile);
        reader.onload = () => {
          const buffer = reader.result;
          workbook.xlsx.load(buffer).then(workbook => {
            var worksheet = workbook.getWorksheet();

            const keys = Object.keys(self.form.exportForm);

            for (let elementCell of keys) {
              worksheet.getCell(
                self.form.exportForm[elementCell].position
              ).value = self.form.exportForm[elementCell].value;
            }

            worksheet.getCell(this.form.simulationForm.powerPeak.position).value = forms.customerForm.projectParametersForm.maximumPowerPeak
            worksheet.getCell(this.form.simulationForm.producible.position).value = forms.customerForm.projectParametersForm.producible
            worksheet.getCell(this.form.simulationForm.initialSellPrice.position).value = forms.customerForm.electricalIdentityForm.winterFullHoursTariff * 1000
            worksheet.getCell(this.form.simulationForm.surplusValorization.position).value = forms.customerForm.projectParametersForm.surplusValuationTariff * 1000


            workbook.xlsx
              .writeBuffer({
                base64: true
              })
              .then(function(xls64) {
                var a = document.createElement("a");
                var data = new Blob([xls64], {
                  type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });

                var url = URL.createObjectURL(data);
                a.href = url;
                a.download = "synthese.xlsx";
                document.body.appendChild(a);
                a.click();
                setTimeout(function() {
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);
                }, 0);
              })
              .catch(function(error) {
                self.$parent.errorTitle = "Simulation error";
                self.$parent.errorMessage = error.message;
                self.$parent.$bvModal.show("error-modal");
              });
          });
        };
      }
      document.getElementById("button-upload").value = "";
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    closePage() {
      this.$parent.page = 0;
    },
    reset(evt) {
      let keys = Object.keys(this.form.exportForm)
      
      for (let index = 0; index < keys.length; index++) {
        const element = this.form.exportForm[keys[index]];
        element.value = ''
      }
    },
      goToWithoutCheck: function (route) {
          if (route === "/load_curve" && forms.customerForm.consumptionCurveForm) {
              this.$router.push(route);
          }
          if (route === "/scenario" && forms.customerForm.scenariosForm) {
              this.$router.push(route);
          }
          if (route === "/goal" && forms.customerForm.consumptionCurveForm.annualConsumption) {
              this.$router.push(route);
          }
          if (route === "/information") {
              this.$router.push(route);
          }
      }
  }
};
</script>
