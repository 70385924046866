import { axiosRequest } from '../middlewares/axios';
import { conf } from '../config/config';
import forms from '../constante/Forms';
import jwtDecode from 'jwt-decode';
import router from '../router';
import store from '../store';
import customerUtil from '../utils/customer';
import validation from '../utils/validation';
import util from '../utils/util';
import { customerService } from "@/services/customer";
import Vue from 'vue';

export const authenticationService = {
    login,
};

interface DecodedJWT {
    sub: string;
    roles: string[];
    exp: number;
    user_id: number;
    customer_id: number[];
}

const status = {
    logged: false,
    jwtToken: null
};

async function login(email, password) {
    try {
        let response = await axiosRequest.postRequest(`${conf.API_URL}/auth/submit`, {
            email,
            password
        });

        if (response.statusCode === 200) {
            const decoded = jwtDecode<DecodedJWT>(response.data.jwt);
            localStorage.setItem('jwtToken', response.data.jwt);
            localStorage.setItem('user', JSON.stringify({
                email: decoded.sub,
                roles: decoded.roles,
                exp: decoded.exp,
                user_id: decoded.user_id,
                customer_id: decoded.customer_id
            }));
            status.logged = true;
            status.jwtToken = response.data.jwt;

            if (decoded.roles.includes('ROLE_admin') || decoded.roles.includes('ROLE_user')) {
                await router.push('/');
                //window.location.reload();
                return true;
            }
            return false;
        }

    } catch (error) {
        if (error.response.status === 401) {
            console.log(error)
            Vue.toasted.error("Email ou mot de passe incorrect.");
        }
        else if (error.response.status === 403) {
            const customMessage = error.response.data.message;
            console.log(customMessage);
            Vue.toasted.error("Vous n'avez pas les autorisations nécessaires");
        }else{
			const customMessage = error.response.data.message;
            console.log("Error from backend : ",customMessage);
            Vue.toasted.error("Erreur interne du serveur : "+error.response.status);
		}
        return false;
    }
}


