<template>
  <div class="login-container">
    <div class="bloc_content">
      <div class="login-banner">Connectez-vous</div>
      <form @submit.prevent="login" class="form-horizontal">
        <div class="bloc_form">
          <label for="email">Email</label>
          <input v-model="email" type="text" id="email" class="form-control input_form" placeholder="Email" required>
        </div>
        <div class="bloc_form">
          <label for="password">Mot de passe</label>
          <input v-model="password" type="password" id="password" class="form-control input_form" placeholder="Mot de passe" required>
        </div>
        <div class="form-actions">
          <button type="submit" class="btn btn-primary">Login</button>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import {authenticationService} from '../services/authentication';

export default {
  data() {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    async login() {
       await authenticationService.login(this.email, this.password);
    },
  },
};
</script>


