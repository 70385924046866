<template>
  <div class="app container-fluid">
    <div class="row">
      <div class="col-12">
        <Menu />
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "./components/Menu";
import router from './router';

export default {
  name: "App",
  components: {
    Menu
  },
  data() {
    return {
      tokenCheckInterval: null
    };
  },
  mounted() {
    this.startTokenCheck();
  },
  beforeDestroy() {
    this.clearTokenCheck();
  },
  methods: {
    startTokenCheck() {
      this.tokenCheckInterval = setInterval(() => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const now = Math.floor(Date.now() / 1000);
        if (user.exp && now >= user.exp) {
          if (router.currentRoute.name !== 'login') {
            router.push({ name: 'login' }).catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                console.error(err);
              }
            });
          }
        }
      }, 30000); // Check every 30 seconds
    },
    clearTokenCheck() {
      if (this.tokenCheckInterval) {
        clearInterval(this.tokenCheckInterval);
        this.tokenCheckInterval = null;
      }
    }
  }
};
</script>


<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
