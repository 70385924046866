<template>
  <div class="row">
    <div class="col-12">
      <b-form @reset="onReset" @submit="onSubmit">
        <b-form-group
            id="monthly-simulation-radio-group-month"
            class="tag_timeline row"
            style="text-align:center"
        >
          <b-form-radio-group
              id="monthly-simulation-radio-months"
              v-model="selected_month"
              :options="options_month"
              button-variant="cta_month"
              buttons
              class="col"
              @input="selectedMonthChange(selected_month - 1)"
          />
        </b-form-group>

        <b-form-group class="content_customer">
          <b-form-row>
            <b-col>
              <div class="bloc_content" style="margin-right: 10px; height: 290px">
                <h2>Auto-production</h2>
                <b-form-row class="row align_center margin_top_10 margin_bottom_10">
                  <b-col class="col-6 align_center">
                    <span class="sous_titre">Objectifs / production</span>
                    <b-form-group id="yearly-simularion-production-target" class="valeurs">
                      {{
                        forms.customerForm.simulationForm.monthlySimulation[
                        this.selected_month - 1
                            ].autoProductionGoal
                      }}
                      kWh
                    </b-form-group>

                    <radial-progress-bar
                        :completed-steps="
                        forms.customerForm.simulationForm.monthlySimulation[
                          this.selected_month - 1
                        ].autoProductionRateGoal
                      "
                        :diameter="80"
                        :strokeWidth="5"
                        :total-steps="100"
                        innerStrokeColor="#D4D4D4"
                        startColor="#009FE3"
                        stopColor="#009FE3"
                        style="margin: auto"
                    >
                      <div>
                        <h1>
                          {{
                            forms.customerForm.simulationForm.monthlySimulation[
                            this.selected_month - 1
                                ].autoProductionRateGoal
                          }}
                        </h1>%
                      </div>
                    </radial-progress-bar>
                  </b-col>
                  <b-col class="col-6 align_center">
                    <span class="sous_titre">Valeur de la simulation</span>
                    <b-form-group id="yearly-simulation-production-simulated" class="valeurs">
                      {{
                        forms.customerForm.simulationForm.monthlySimulation[
                        this.selected_month - 1
                            ].simulationAutoProduction
                      }}
                      kWh
                    </b-form-group>

                    <radial-progress-bar
                        :completed-steps="
                        forms.customerForm.simulationForm.monthlySimulation[
                          this.selected_month - 1
                        ].simulationAutoProductionRate
                      "
                        :diameter="80"
                        :strokeWidth="5"
                        :total-steps="100"
                        innerStrokeColor="#D4D4D4"
                        startColor="#ED956A"
                        stopColor="#ED956A"
                        style="margin: auto"
                    >
                      <div>
                        <h1>
                          {{
                            forms.customerForm.simulationForm.monthlySimulation[
                            this.selected_month - 1
                                ].simulationAutoProductionRate
                          }}
                        </h1>%
                      </div>
                    </radial-progress-bar>
                  </b-col>
                </b-form-row>
              </div>
            </b-col>

            <b-col class="col">
              <div class="bloc_content" style="margin-left: 10px; margin-right: 10px; height: 290px">
                <h2>Auto-consommation</h2>
                <b-form-row class="row align_center margin_top_10 margin_bottom_10">
                  <b-col class="col-12 align_center">
                    <span class="sous_titre" style="padding-bottom:22px">Valeur de la simulation</span>
                    <b-form-group id="yearly-simulation-consumption-simulated" class="valeurs">
                      {{
                        forms.customerForm.simulationForm.monthlySimulation[
                        this.selected_month - 1
                            ].autoConsumption
                      }}
                      kWh
                    </b-form-group>

                    <radial-progress-bar
                        :completed-steps="
                        forms.customerForm.simulationForm.monthlySimulation[
                          this.selected_month - 1
                        ].simulationAutoConsumptionRate
                      "
                        :diameter="80"
                        :strokeWidth="5"
                        :total-steps="100"
                        innerStrokeColor="#D4D4D4"
                        startColor="#B5D131"
                        stopColor="#B5D131"
                        style="margin: auto"
                    >
                      <div>
                        <h1>
                          {{
                            forms.customerForm.simulationForm.monthlySimulation[
                            this.selected_month - 1
                                ].simulationAutoConsumptionRate
                          }}
                        </h1>%
                      </div>
                    </radial-progress-bar>
                  </b-col>
                </b-form-row>
              </div>
            </b-col>

            <b-col class="col">
              <div class="bloc_content" style="margin-left: 10px; height: 290px">
                <h2>Economie</h2>
                <b-form-row class="row align_center margin_top_10 margin_bottom_10">
                  <b-col class="col-6 align_center">
                    <span class="sous_titre" style="padding-bottom:22px">Objectifs annuels</span>
                    <b-form-group id="yearly-simularion-economy-target" class="valeurs">
                      {{
                        forms.customerForm.simulationGoalsForm.maximumAnnualNetworkBilling
                      }}
                      &euro;
                    </b-form-group>

                  </b-col>
                  <b-col class="col-6 align_center">
                    <span class="sous_titre">Valorisation auto consommation</span>
                    <b-form-group id="yearly-simulation-economy-simulated" class="valeurs">
                      {{
                        forms.customerForm.simulationForm.monthlySimulation[
                        this.selected_month - 1
                            ].saving
                      }}
                      &euro;
                    </b-form-group>

                    <span class="sous_titre">Valorisation surplus</span>
                    <b-form-group
                        id="yearly-simulation-economy-simulated"
                        class="valeurs"
                    >{{
                        forms.customerForm.simulationForm.monthlySimulation[this.selected_month - 1].injectedEnergyValuation
                      }}
                      &euro;
                    </b-form-group
                    >
                  </b-col>
                </b-form-row>
              </div>
            </b-col>
          </b-form-row>
        </b-form-group>

        <b-form-group id="monthly-simulation-chart-week-day-form" class="graph">
          <b-card id="monthly-simulation-card-chart-week-day"
                  :title="`Journée Type en Semaine (en kWh) pour ${options_month[selected_month - 1].text}`">
            <rounded-bar-chart
                id="monthly-simulation-chart-week-day"
                :chart-data="datacollection_weekday"
                :height="400"
                :isChanged="isChanged"
                :options="options"
            ></rounded-bar-chart>
          </b-card>
        </b-form-group>

        <b-form-group id="monthly-simulation-chart-week-end-form">
          <b-card id="monthly-simulation-card-chart-week-end"
                  :title="`Journée Type en Week End (en kWh) pour ${options_month[selected_month - 1].text}`">
            <rounded-bar-chart
                id="monthly-simulation-chart-week-end"
                :chart-data="datacollection_weekend"
                :height="400"
                :isChanged="isChanged"
                :options="options"
            ></rounded-bar-chart>
          </b-card>
        </b-form-group>

        <b-form-group class="content_customer bottom_bloc">
          <b-form-row>
            <b-col style="margin-top: 10px">
              <div class="bloc_content" style=" margin-right: 10px">
                <h2>Consommation mensuelle</h2>
                <div class="row">
                  <div class="col-12 align_center">
                    <span class="valeurs_big">
                      {{
                        forms.customerForm.simulationForm.monthlySimulation[this.selected_month - 1]
                            .consumption
                      }}
                    </span>
                    <span class="valeurs_big_legende">kWh</span>
                  </div>
                </div>
              </div>
            </b-col>

              <b-col>
                  <div class="bloc_content" style=" margin-right: 10px">
                      <h2>Production prévisionnelle</h2>
                      <div class="row">
                          <div class="col-12 align_center">
                    <span class="valeurs_big">
                      {{
                            forms.customerForm.simulationForm.monthlySimulation[this.selected_month - 1]
                                    .production
                        }}
                    </span>
                              <span class="valeurs_big_legende">kWh</span>
                          </div>
                      </div>
                  </div>
              </b-col>

            <b-col style="margin-top: 10px">
              <div class="bloc_content" style="margin-left: 10px; margin-right: 10px">
                <h2>Réduction de puissance réseau</h2>
                <div class="row">
                  <div class="col-12 align_center">
                    <span class="valeurs_big">
                      {{
                        forms.customerForm.simulationForm.monthlySimulation[this.selected_month - 1]
                            .subscribedPowerReduction
                      }}
                    </span>
                    <span class="valeurs_big_legende">kVA</span>
                  </div>
                </div>
                  <div class="row">
                      <div class="col-12 align_center">
                          <span class="valeurs_bottom">Nouveau soutirage maximum : {{forms.customerForm.simulationForm.monthlySimulation[this.selected_month - 1].maximumPurchase }} kVA</span>
                      </div>
                  </div>
              </div>
            </b-col>

            <b-col style="margin-top: 10px">
              <div class="bloc_content" style="margin-left: 10px">
                <h2>Réduction du soutirage réseau</h2>
                <div class="row">
                  <div class="col-12 align_center">
                    <span class="valeurs_big">
                      {{
                        forms.customerForm.simulationForm.monthlySimulation[this.selected_month - 1]
                            .networkWithdrawReduction
                      }}
                    </span>
                    <span class="valeurs_big_legende">kWh</span>
                  </div>
                </div>
              </div>
            </b-col>

            <b-col style="margin-top: 10px">
              <div class="bloc_content" style="margin-left: 10px">
                <h2>Ratio HC/HP</h2>
                <div class="row">
                  <div class="col-12 align_center">
                    <span
                        class="valeurs_big">{{ forms.customerForm.simulationForm.monthlySimulation[this.selected_month - 1].newFullHoursToOffPeakHoursRate }} %</span>
                    <span class="valeurs_bottom">
                    <i>( {{
                        forms.customerForm.simulationForm.monthlySimulation[this.selected_month - 1].currentFullHoursToOffPeakHoursRate
                      }} % )</i>
                    </span>
                  </div>
                </div>
              </div>
            </b-col>
          </b-form-row>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import RoundedBarChart from "./charts/RoundedBarChart";
import RadialProgressBar from "vue-radial-progress";
import {updateSingleValues} from "@/utils/ratio";
import _ from "lodash";

export default {
  components: {
    RoundedBarChart,
    RadialProgressBar
  },
  mounted() {
    if (this.form && this.form.headerSimulationForm) {
      updateSingleValues(this);
    }
    this.calculateAxes();
  },
  watch: {
    "forms.customerForm.simulationForm": {
      deep: true,
      handler(newParameter) {
        this.selectedMonthChange(this.selected_month - 1);
      }
    }
  },
  data() {
    return {
      isChanged: false,
      selected_month: 1,
      options_month: [
        {text: "Janv.", value: 1},
        {text: "Fév.", value: 2},
        {text: "Mars", value: 3},
        {text: "Avr.", value: 4},
        {text: "Mai", value: 5},
        {text: "Juin", value: 6},
        {text: "Juill.", value: 7},
        {text: "Août", value: 8},
        {text: "Sept.", value: 9},
        {text: "Oct.", value: 10},
        {text: "Nov.", value: 11},
        {text: "Dec.", value: 12}
      ],
      datacollection_weekday: {
        labels: [
          "0h",
          "1h",
          "2h",
          "3h",
          "4h",
          "5h",
          "6h",
          "7h",
          "8h",
          "9h",
          "10h",
          "11h",
          "12h",
          "13h",
          "14h",
          "15h",
          "16h",
          "17h",
          "18h",
          "19h",
          "20h",
          "21h",
          "22h",
          "23h"
        ],
        datasets: [
          {
            label: "Auto consommation",
            backgroundColor: "#B5D131",
            order: 3,
            data:
            forms.customerForm.simulationForm.monthlySimulation[0]
                .weekDaysHourlyAutoConsumption
          },
          {
            label: "Achat",
            backgroundColor: "#D4D4D4",
            order: 4,
            data: forms.customerForm.simulationForm.monthlySimulation[0].weekDaysHourlyPurchase
          },
          {
            label: "Valorisation surplus",
            backgroundColor: "#009FE3",
            order: 2,
            data:
            forms.customerForm.simulationForm.monthlySimulation[0]
                .weekDaysHourlyInjectedSurplus
          },
          {
            yAxisID: "bar-y-axis",
            label: "Production",
            lineTension: 0,
            borderColor: "#ED956A",
            backgroundColor: "rgba(255, 255, 255, 0)",
            borderWidth: 2,
            showLine: true,
            type: "line",
            fill: true,
            order: 0,
            pointRadius: 0,
            data:
            forms.customerForm.simulationForm.monthlySimulation[0].weekDaysHourlyProduction
          },
          {
            yAxisID: "bar-y-axis",
            label: "Stock batterie",
            lineTension: 0,
            borderColor: "#ED956A",
            backgroundColor: "rgba(255, 255, 255, 0)",
            borderWidth: 2,
            showLine: true,
            borderDash: [5, 5],
            type: "line",
            order: 1,
            pointRadius: 0,
            fill: true,
            data:
            forms.customerForm.simulationForm.monthlySimulation[0].weekDaysHourlyBatteryStock
          }
        ]
      },
      datacollection_weekend: {
        labels: [
          "0h",
          "1h",
          "2h",
          "3h",
          "4h",
          "5h",
          "6h",
          "7h",
          "8h",
          "9h",
          "10h",
          "11h",
          "12h",
          "13h",
          "14h",
          "15h",
          "16h",
          "17h",
          "18h",
          "19h",
          "20h",
          "21h",
          "22h",
          "23h"
        ],
        datasets: [
          {
            label: "Auto consommation",
            backgroundColor: "#B5D131",
            order: 2,
            data:
            forms.customerForm.simulationForm.monthlySimulation[0]
                .weekendHourlyAutoConsumption
          },
          {
            label: "Achat",
            backgroundColor: "#D4D4D4",
            order: 3,
            data:
            forms.customerForm.simulationForm.monthlySimulation[0].weekendHourlyPurchase
          },
          {
            label: "Valorisation surplus",
            backgroundColor: "#009FE3",
            order: 4,
            data:
            forms.customerForm.simulationForm.monthlySimulation[0]
                .weekendHourlyInjectedSurplus
          },
          {
            yAxisID: "bar-y-axis",
            label: "Production",
            lineTension: 0,
            borderColor: "#ED956A",
            backgroundColor: "rgba(255, 255, 255, 0)",
            borderWidth: 2,
            showLine: true,
            type: "line",
            fill: true,
            pointRadius: 0,
            order: 0,
            data:
            forms.customerForm.simulationForm.monthlySimulation[0]
                .weekendHourlyProduction
          },
          {
            yAxisID: "bar-y-axis",
            label: "Stock batterie",
            lineTension: 0,
            borderColor: "#ED956A",
            backgroundColor: "rgba(255, 255, 255, 0)",
            borderWidth: 2,
            showLine: true,
            type: "line",
            fill: true,
            borderDash: [5, 5],
            pointStyle: 'star',
            pointRadius: 0,
            order: 1,
            data:
            forms.customerForm.simulationForm.monthlySimulation[0]
                .weekendHourlyBatteryStock
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              labelString: "kWh",
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              },
              stacked: false
            },
            {
              label: "kWh",
              id: "bar-y-axis",
              stacked: false,
              gridLines: {
                label: "kWh",
                drawOnChartArea: false,
                color: "#ED956A",
                lineWidth: 2,
              },
              position: "right",
              display: true
            }
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: false
              },
              stacked: true
            }
          ]
        },
        legend: {
          display: true,
          position: "bottom",
          usePointStyle: true
        },
        tooltips: {
          enabled: true,
          mode: "single"
        },
        responsive: true,
        maintainAspectRatio: false,
        height: 400,
        cornerRadius: 50,
        animation: {
          onComplete: function () {
            const url_base64jpEnd = document.getElementById(
                "monthly-simulation-chart-week-end"
            );
            if (url_base64jpEnd.childNodes.length > 0) {
              forms.customerForm.simulationForm.annualSimulation.pictureMonthlySimulationEnd = null;
              forms.customerForm.simulationForm.annualSimulation.pictureMonthlySimulationEnd = _.find(
                  url_base64jpEnd.childNodes,
                  node => node.localName === "canvas"
              ).toDataURL("image/jpg");
            }

            const url_base64jpDay = document.getElementById(
                "monthly-simulation-chart-week-day"
            );
            if (url_base64jpDay.childNodes.length > 0) {
              forms.customerForm.simulationForm.annualSimulation.pictureMonthlySimulationDay = null;
              forms.customerForm.simulationForm.annualSimulation.pictureMonthlySimulationDay = _.find(
                  url_base64jpDay.childNodes,
                  node => node.localName === "canvas"
              ).toDataURL("image/jpg");
            }
          }
        }
      }
    };
  },
  methods: {
    calculateAxes() {
      let max = [
        ...forms.customerForm.simulationForm.monthlySimulation[0]
            .weekDaysHourlyAutoConsumption,
        ...forms.customerForm.simulationForm.monthlySimulation[0].weekDaysHourlyPurchase,
        ...forms.customerForm.simulationForm.monthlySimulation[0]
            .weekDaysHourlyInjectedSurplus,
        ...forms.customerForm.simulationForm.monthlySimulation[0].weekDaysHourlyProduction,
        ...forms.customerForm.simulationForm.monthlySimulation[0].weekDaysHourlyBatteryStock
      ];
      let maxValue = _.max(max);
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onReset(evt) {
      evt.preventDefault();
    },
    selectedMonthChange(selMonth) {
      this.datacollection_weekday.datasets.forEach(dataset => {
        switch (dataset.label) {
          case "Auto consommation":
            dataset.data =
                forms.customerForm.simulationForm.monthlySimulation[
                    selMonth
                    ].weekDaysHourlyAutoConsumption;
            break;
          case "Achat":
            dataset.data =
                forms.customerForm.simulationForm.monthlySimulation[
                    selMonth
                    ].weekDaysHourlyPurchase;
            break;
          case "Valorisation surplus":
            dataset.data =
                forms.customerForm.simulationForm.monthlySimulation[
                    selMonth
                    ].weekDaysHourlyInjectedSurplus;
            break;
          case "Production":
            dataset.data =
                forms.customerForm.simulationForm.monthlySimulation[
                    selMonth
                    ].weekDaysHourlyProduction;
            break;
          case "Stock batterie":
            dataset.data =
                forms.customerForm.simulationForm.monthlySimulation[
                    selMonth
                    ].weekDaysHourlyBatteryStock;
            break;
        }
      });

      this.datacollection_weekend.datasets.forEach(dataset => {
        switch (dataset.label) {
          case "Auto consommation":
            dataset.data =
                forms.customerForm.simulationForm.monthlySimulation[
                    selMonth
                    ].weekendHourlyAutoConsumption;
            break;
          case "Achat":
            dataset.data =
                forms.customerForm.simulationForm.monthlySimulation[
                    selMonth
                    ].weekendHourlyPurchase;
            break;
          case "Valorisation surplus":
            dataset.data =
                forms.customerForm.simulationForm.monthlySimulation[
                    selMonth
                    ].weekendHourlyInjectedSurplus;
            break;
          case "Production":
            dataset.data =
                forms.customerForm.simulationForm.monthlySimulation[
                    selMonth
                    ].weekendHourlyProduction;
            break;
          case "Stock batterie":
            dataset.data =
                forms.customerForm.simulationForm.monthlySimulation[
                    selMonth
                    ].weekendHourlyBatteryStock;
            break;
        }
      });

      this.isChanged = !this.isChanged;
      this.$forceUpdate();
    }
  }
};
</script>
