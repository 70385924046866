import { axiosRequest } from '../middlewares/axios'
import {conf} from '../config/config'

export const simulationService = {
  getSimulation,
  getInjectionCurve
}

async function getSimulation({ params } : { params:any }) {
  return await axiosRequest.postRequest(conf.API_URL + '/simulations/' + params.customerId, params)
}

async function getInjectionCurve({ params } : { params:any }) {
  return await axiosRequest.postRequest(conf.API_URL + '/simulations/injectedCurveCsv/' + params.customerId, params)
}