import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import BootstrapVue, { FormPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import VueNumeric from 'vue-numeric'

import VueGlobalVar from 'vue-global-var'
import Forms from './constante/Forms'

// Import Vue Toasted
import Toasted from 'vue-toasted';

// Popper and jQuery
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
import jquery from 'vue-jquery'

// Vue Form Wizard
import FormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import './assets/css/custom.css'

// Using Vue plugins
Vue.use(BootstrapVue)
Vue.use(FormPlugin)
Vue.use(ElementUI)
Vue.use(VueNumeric)
Vue.use(VueGlobalVar, {
  globals: {
    forms: Forms
  }
})
Vue.use(Popper)
Vue.use(jquery)
Vue.use(FormWizard)

// Use Vue Toasted with options
Vue.use(Toasted, {
  position: 'top-center',
  duration: 5000,
  keepOnHover: true,
  singleton: true
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
