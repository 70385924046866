<template>
  <li class="col">
    <div class="bloc_content">
      <h2>Paramètres du projet</h2>
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group id="project-input-group-helda" class="bloc_form">
          <label>Profil de production</label>
          <b-form-select
              id="project-input-helda"
              v-model="forms.customerForm.projectParametersForm.orientationReferential.id"
              :options="profiles"
              class="input_form"
              required
          />
        </b-form-group>

        <b-form-group id="project-input-group-minimal-peak-power" class="bloc_form">
          <label>
            Puissance crête minimale
            <i>(kWc)</i>
          </label>
          <b-form-input
              id="project-input-minimal-peak-power"
              v-model="forms.customerForm.projectParametersForm.minimumPowerPeak"
              required
              class="input_form"
              type="number"
              step="any"
          />
        </b-form-group>

        <b-form-group id="project-input-group-maximal-peak-power" class="bloc_form">
          <label>
            Puissance crête maximale
            <i>(kWc)</i>
          </label>
          <b-form-input
              id="project-input-maximal-peak-power"
              v-model="forms.customerForm.projectParametersForm.maximumPowerPeak"
              required
              class="input_form"
              type="number"
              step="any"
          />
        </b-form-group>

        <b-form-group id="project-input-group-storage-capacity-maximal" class="bloc_form">
          <label>
            Capacité de stockage max.
            <i>(kWh)</i>
          </label>
          <b-form-input
              id="project-input-storage-capacity-maximal"
              v-model="forms.customerForm.projectParametersForm.maximumStorageCapacity"
              required
              class="input_form"
              type="number"
              step="any"
          />
        </b-form-group>

        <b-form-group id="project-input-group-battery-performance" class="bloc_form">
          <label>
            Rendement des batteries
            <i>(%)</i>
          </label>
          <b-form-input
              id="project-input-battery-performance"
              v-model="forms.customerForm.projectParametersForm.batteryPerformanceRate"
              required
              class="input_form"
              type="number"
              step="any"
              :state="percentageState(this.forms.customerForm.projectParametersForm.batteryPerformanceRate)"
          />
        </b-form-group>

        <b-form-group id="project-input-group-charge-discharge-power" class="bloc_form">
          <label>Puissance de charge/décharge</label>
          <b-form-input
              id="project-input-charge-discharge-power"
              v-model="forms.customerForm.projectParametersForm.maximumChargeDischargePower"
              required
              class="input_form"
              type="number"
              step="any"
          />
        </b-form-group>

        <b-form-group id="project-input-group-injection-power" class="bloc_form">
          <label>
            Puissance d'injection max.
            <i>(kVA)</i>
          </label>
          <b-form-input
              id="project-input-injection-power"
              v-model="forms.customerForm.projectParametersForm.maximumInjectionPower"
              required
              class="input_form"
              type="number"
              step="any"
          />
        </b-form-group>

        <b-form-group id="project-input-group-productibles" class="bloc_form">
          <label>
            Productible
            <i>(kWh/kWc)</i>
          </label>
          <b-form-input
              id="project-input-productibles"
              v-model="forms.customerForm.projectParametersForm.producible"
              required
              class="input_form"
              type="number"
              step="any"
              :readonly="!isAdmin"
          />
        </b-form-group>

        <b-form-group id="input-group-surplus-valuation-rate" class="bloc_form">
          <label>
            Tarif de valorisation du surplus
            <i>(€/kWh)</i>
          </label>
          <b-form-input
              id="project-input-surplus-valuation-rate"
              v-model="forms.customerForm.projectParametersForm.surplusValuationTariff"
              required
              class="input_form"
              type="number"
              step="any"
          />
        </b-form-group>

        <b-form-group id="input-group-average-carbon-content" class="bloc_form">
          <label>
            Contenu moyen carbone par
            <i>kWh (g)</i>
          </label>
          <b-form-input
              id="project-input-average-carbon-content"
              v-model="forms.customerForm.projectParametersForm.averageCarbonContentPerKWh"
              required
              class="input_form"
              type="number"
              step="any"
              :readonly="!isAdmin"
          />
        </b-form-group>

        <b-form-group
            id="project-input-group-journee-type"
            label="Journée type"
            label-for="project-input-journee-type"
            class="bloc_form"
        >
          <b-form-row>
            <b-col>
              <b-form-group
                  id="project-input-group-journee-type-semaine"
                  label="Semaine"
                  class="label02"
              >
                <b-form-select
                    id="project-input-journee-type-semaine"
                    v-model="forms.customerForm.projectParametersForm.weekStartDay"
                    :options="week"
                    class="input_form"
                    required
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                  id="project-input-group-journee-type-week-end"
                  label="Week end"
                  class="label02"
              >
                <b-form-select
                    id="project-input-journee-type-week-end"
                    v-model="forms.customerForm.projectParametersForm.weekendStartDay"
                    :options="weekend"
                    class="input_form"
                    required
                />
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form-group>

        <b-form-group id="project-input-group-description" class="bloc_form">
          <label>Description</label>
          <b-form-textarea
              id="project-input-description"
              v-model="forms.customerForm.projectParametersForm.description"
              required
              class="input_form"
              rows="3"
          />
        </b-form-group>
      </b-form>
    </div>
  </li>
</template>

<script>
import validation from "../utils/validation"

export default {
  data() {
    return {
      week: [
        { value: 1, text: "Lundi" },
        { value: 2, text: "Mardi" },
        { value: 3, text: "Mercredi" },
        { value: 4, text: "Jeudi" },
        { value: 5, text: "Vendredi" }
      ],
      weekend: [
        { value: 6, text: "Samedi" },
        { value: 0, text: "Dimanche" }
      ],
      profiles: [],
      referentials: [],
      show: true,
      isAdmin: false
    };
  },
  async mounted() {
    await this.$store.dispatch('referential/getAll');
    this.referentials = await this.$store.getters['referential/getAll'];

    this.profiles = this.referentials.map(referential => {
      return { value: referential.id, text: referential.label };
    });

    if (!forms.id || forms.id < 0) {
      forms.customerForm.projectParametersForm.referentialId = this.profiles[0].value;
    }

    this.checkIfAdmin();
  },
  methods: {
    percentageState(value) {
      return validation.isPercentageValid(value) ? null : false;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onReset(evt) {
      evt.preventDefault();
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    checkIfAdmin() {
      const user = JSON.parse(localStorage.getItem('user'));
      this.isAdmin = user && user.roles.includes('ROLE_admin');
    }
  }
};
</script>
