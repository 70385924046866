<template>
  <li class="col-8">
    <div class="bloc_content">
      <h2>Analyse de la courbe de charge</h2>
      <b-form-group id="load-curve-analyse-display-1">
        <b-form-row>
          <b-col>
            <b-form-group
                id="load-curve-analyse-display-group-yearly-consumption"
                class="bloc_form etat02"
                style="padding-right:25px"
            >
              <label>Consommation annuelle <i>(kWh/an)</i></label>
              <vue-numeric id="load-curve-analyse-display-yealry-consumption" v-model="forms.customerForm.consumptionCurveForm.annualConsumption" :precision="0"
                           class="input_form" separator="space"></vue-numeric>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                id="load-curve-analyse-display-group-HP-HC-balance"
                class="bloc_form etat02"
                style="padding-left:25px"
            >
              <label>Rapport HC/HP <i>(%)</i></label>
              <vue-numeric id="load-curve-analyse-display-HP-HC-balance" v-model="forms.customerForm.consumptionCurveForm.fullHoursToOffPeakHoursRate" :precision="0"
                           class="input_form"
                           separator="space"></vue-numeric>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
                id="load-curve-analyse-display-group-average-power"
                class="bloc_form etat02"
                style="padding-right:25px"
            >
              <label>Puissance moy. soutirée <i>(kVA)</i></label>
              <vue-numeric id="load-curve-analyse-display-average-power" v-model="forms.customerForm.consumptionCurveForm.averageWithdrawPower" :precision="0"
                           class="input_form" separator="space"></vue-numeric>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="load-curve-analyse-display-group-maximum-power" class="bloc_form etat02" style="padding-left:25px">
              <label>Puissance max. soutirée <i>(kVA)</i></label>
              <vue-numeric id="load-curve-analyse-display-maximum-power" v-model="forms.customerForm.consumptionCurveForm.maximumWithdrawPower" :precision="0" class="input_form" separator="space"></vue-numeric>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group id="load-curve-analyse-display-group-annual-billing" class="bloc_form etat02">
              <label>estimation du coût annuel <i>(€)</i></label>
              <vue-numeric id="load-curve-analyse-display-average-power" v-model="forms.customerForm.consumptionCurveForm.annualBilling" :precision="0" class="input_form" separator="space"></vue-numeric>
            </b-form-group>
          </b-col>
        </b-form-row>

      </b-form-group>

      <b-form-group id="load-curve-analyse-group-chart">
        <label>Consommation mensuelle <i>(kWh)</i></label>
        <rounded-bar-chart
            id="chart-yearly-consumption"
            :chart-data="datacollection"
            :height="400"
            :is-changed="isChanged"
            :options="options"
        ></rounded-bar-chart>
      </b-form-group>
    </div>
  </li>
</template>

<script>
import RoundedBarChart from "./charts/RoundedBarChart";

export default {
  components: {
    RoundedBarChart
  },
  data: function () {
    return {
      isChanged: false,
      datacollection: {
        labels: [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre"
        ],
        datasets: [
          {
            label: "Consommation mensuelle (kWh)",
            backgroundColor: "#00A2E9",
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "#249EBF",
            data: forms.customerForm.consumptionCurveForm.monthlyConsumptions,
            barPercentage: 0.5
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: false
              }
            }
          ]
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: true,
          mode: "single",
          callbacks: {
            label: function (tooltipItems, data) {
              return tooltipItems.yLabel + " kWh";
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        height: 400,
        cornerRadius: 50
      }
    };
  },
  created() {
    this.$watch('forms', function () {
      this.datacollection.datasets[0].data = forms.customerForm.consumptionCurveForm.monthlyConsumptions
      this.isChanged = !this.isChanged
    }, {deep: true})
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
    },
    onReset(evt) {
      evt.preventDefault();
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  }
};
</script>
