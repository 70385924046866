<template>
  <b-modal id="suppression-modal" :title="title">
    <p>{{ message }}</p>

    <template v-slot:modal-footer="{ok}">
      <b-button size="sm" @click="ok()">NON</b-button>
      <b-button size="sm" @click="deleteClient()">OUI</b-button>
    </template>
  </b-modal>
</template>

<script>
import util from "@/utils/util";

export default {
  props: ['message','title', 'id'],
  methods: {
    async deleteClient(){
      const id = this.id
      await this.$store.dispatch('customer/delete', {id})
      const response = this.$store.getters['customer/delete']

        window.location.reload()

    },
  }
}

</script>