const namespaced = true

export default {
    namespaced,
    state: {
        autoProduction: {
            objectif: 44,
            valeur: 37,
        },
        autoConsommation: {
            objectif: 53,
            valeur: 64,
        },
        economie: {
            objectif: 28,
            valeur: 34,
        },
    },
    getters: {
        getAutoProduction: (state: any) => {
            return state.autoProduction
        },
        getAutoConsommation: (state: any) => {
            return state.autoConsommation
        },
        getEconomie: (state: any) => {
            return state.economie
        },
    },
    mutations: {
        setAutoProduction(state: any, valeur:any) {
            state.autoProduction = valeur
        },
        setAutoConsommation(state: any, valeur:any) {
            state.autoConsommation = valeur
        },
        setEconomie(state: any, valeur:any) {
            state.economie = valeur
        },
    }
}
