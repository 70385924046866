export default {
    id: -1,
    customerForm: {
        id: 0,
        customerIdentityForm: {
            companyName: "",
            logo: "",
            address: "",
            addressComplement: "",
            postalCode: "",
            city: "",
            country: ""
        },
        electricalIdentityForm: {
            subscription: "",
            subscribedPower: 0,
            uniqueTariff: false,
            purchaseTariff: null,
            offPeakHours: [{startOffPeakHours: "", endOffPeakHours: ""}],
            winterFullHoursTariff: 0,
            winterOffHoursTariff: 0,
            summerFullHoursTariff: 0,
            summerOffHoursTariff: 0,
            seasonReferentialFormatted: {
                summerStartDate: "",
                winterStartDate: ""
            },
            summerStartMonth: '',
            summerStartDay: '',
            winterStartMonth: '',
            winterStartDay: '',
            energyTaxes: 0
        },
        projectParametersForm: {
            orientationReferential: {
                id: 1,
                label: "",
            },
            minimumPowerPeak: 0,
            maximumPowerPeak: 0,
            maximumStorageCapacity: 0,
            batteryPerformanceRate: 0,
            maximumChargeDischargePower: 0,
            maximumInjectionPower: 0,
            producible: 0,
            surplusValuationTariff: 0,
            averageCarbonContentPerKWh: 0,
            weekStartDay: 1,
            weekendStartDay: 6,
            description: ""
        },
        projectIdentityForm: {
            projectStatus: "",
            projectManager: ""
        },
        userIdentificationForm: {
            email: "",
            password: ""
        },
        consumptionCurveForm: {
            annualConsumption: 0,
            fullHoursToOffPeakHoursRate: 0,
            averageWithdrawPower: 0,
            maximumWithdrawPower: 0,
            annualBilling: 0,
            monthlyConsumptions: [],
            createdAt: "",
            updatedAt: "",
            isPartial: false
        },
        simulationGoalsForm: {
            minimumAutoProductionRate: 0,
            maximumWithdrawPower: 0,
            maximumAnnualNetworkBilling: 0
        },
        scenariosForm: {
            columns: [0, 110, 220, 330, 440, 550, 660, 770, 990, 1110],
            indexes: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
            scenarios: [{
                name: "",
                values: [[5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10]]
            }, {
                name: "",
                values: [[5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10]]
            }, {
                name: "",
                values: [[5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    [10, 10, 10, 10, 10, 10, 10, 10, 10, 10]]
            }]
        },
        simulationForm: {
            annualSimulation: {
                pictureMonth: null,
                pictureYear: null,
                pictureMonthlySimulationDay: null,
                pictureMonthlySimulationEnd: null,
                simulationAutoProduction: 0,
                autoProductionGoal: 0,
                autoProductionRateGoal: random(20, 80),
                simulationAutoProductionRate: random(20, 80),
                autoConsumption: 0,
                simulationAutoConsumptionRate: random(20, 80),
                maximumAnnualNetworkBillingGoalRate: 0,
                saving: 0,
                injectedEnergyValuation: 0,
                simulationSavingRate: random(20, 80),
                productionSurplus: 0,
                resalePerYear: 0,
                consumption: random(500, 2500),
                monthlyConsumptions: [random(100, 250), random(100, 250), random(100, 250), random(100, 250), random(100, 250), random(100, 250), random(100, 250), random(100, 250), random(100, 250), random(100, 250), random(100, 250), random(100, 250)],
                purchase: random(500, 2000),
                monthlyPurchases: [random(100, 200), random(100, 200), random(100, 200), random(100, 200), random(100, 200), random(100, 200), random(100, 200), random(100, 200), random(100, 200), random(100, 200), random(100, 200), random(100, 200)],
                subscribedPowerReduction: 0,
                networkWithdrawReduction: 0,
                newAverageWithdrawnPower: 0,
                currentAverageWithdrawnPower: 0,
                batteryCyclesNumber: 0,
                newFullHoursToOffPeakHoursRate: 0,
                currentFullHoursToOffPeakHoursRate: 0,
                powerLimitedLostEnergy: 0,
                carbonOffsetting: 0,
                newBilling: 0,
                currentBilling: 0,
                production: 0,
                maximumPurchase: 0
            },
            monthlySimulation: [{
                simulationAutoProduction: 0,
                autoProductionGoal: 0,
                autoProductionRateGoal: random(20, 80),
                simulationAutoProductionRate: random(20, 80),
                autoConsumption: 0,
                simulationAutoConsumptionRate: random(20, 80),
                maximumAnnualNetworkBillingGoalRate: 0,
                saving: 0,
                injectedEnergyValuation: 0,
                simulationSavingRate: random(20, 80),
                weekDaysHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(20, 60), random(200, 250), random(300, 350), random(150, 200), random(200, 250), random(250, 300), random(100, 180), random(30, 90), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekDaysHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 90), random(270, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 260), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(140, 180), random(180, 240), random(280, 330), random(120, 160), random(40, 90), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 100), random(40, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekendHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 80), random(260, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 70), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 280), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                production: 0,
                consumption: 0,
                purchase: 0,
                currentBilling: 0,
                newBilling: 0,
                maximumPurchase: 0,
                subscribedPowerReduction: 0,
                networkWithdrawReduction: 0,
                newFullHoursToOffPeakHoursRate: 0,
                currentFullHoursToOffPeakHoursRate: 0,
            }, {
                simulationAutoProduction: 0,
                autoProductionGoal: 0,
                autoProductionRateGoal: random(20, 80),
                simulationAutoProductionRate: random(20, 80),
                autoConsumption: 0,
                simulationAutoConsumptionRate: random(20, 80),
                maximumAnnualNetworkBillingGoalRate: 0,
                saving: 0,
                simulationSavingRate: random(20, 80),
                weekDaysHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(20, 60), random(200, 250), random(300, 350), random(150, 200), random(200, 250), random(250, 300), random(100, 180), random(30, 90), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekDaysHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 90), random(270, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 260), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(140, 180), random(180, 240), random(280, 330), random(120, 160), random(40, 90), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 100), random(40, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekendHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 80), random(260, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 70), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 280), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                production: 0,
                consumption: 0,
                purchase: 0,
                currentBilling: 0,
                newBilling: 0,
                maximumPurchase: 0,
                subscribedPowerReduction: 0,
                networkWithdrawReduction: 0,
                newFullHoursToOffPeakHoursRate: 0,
                currentFullHoursToOffPeakHoursRate: 0,
            }, {
                simulationAutoProduction: 0,
                autoProductionGoal: 0,
                autoProductionRateGoal: random(20, 80),
                simulationAutoProductionRate: random(20, 80),
                autoConsumption: 0,
                simulationAutoConsumptionRate: random(20, 80),
                maximumAnnualNetworkBillingGoalRate: 0,
                saving: 0,
                simulationSavingRate: random(20, 80),
                weekDaysHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(20, 60), random(200, 250), random(300, 350), random(150, 200), random(200, 250), random(250, 300), random(100, 180), random(30, 90), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekDaysHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 90), random(270, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 260), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(140, 180), random(180, 240), random(280, 330), random(120, 160), random(40, 90), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 100), random(40, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekendHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 80), random(260, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 70), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 280), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                production: 0,
                consumption: 0,
                purchase: 0,
                currentBilling: 0,
                newBilling: 0,
                maximumPurchase: 0,
                subscribedPowerReduction: 0,
                networkWithdrawReduction: 0,
                newFullHoursToOffPeakHoursRate: 0,
                currentFullHoursToOffPeakHoursRate: 0,
            }, {
                simulationAutoProduction: 0,
                autoProductionGoal: 0,
                autoProductionRateGoal: random(20, 80),
                simulationAutoProductionRate: random(20, 80),
                autoConsumption: 0,
                simulationAutoConsumptionRate: random(20, 80),
                maximumAnnualNetworkBillingGoalRate: 0,
                saving: 0,
                simulationSavingRate: random(20, 80),
                weekDaysHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(20, 60), random(200, 250), random(300, 350), random(150, 200), random(200, 250), random(250, 300), random(100, 180), random(30, 90), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekDaysHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 90), random(270, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 260), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(140, 180), random(180, 240), random(280, 330), random(120, 160), random(40, 90), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 100), random(40, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekendHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 80), random(260, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 70), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 280), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                production: 0,
                consumption: 0,
                purchase: 0,
                currentBilling: 0,
                newBilling: 0,
                maximumPurchase: 0,
                subscribedPowerReduction: 0,
                networkWithdrawReduction: 0,
                newFullHoursToOffPeakHoursRate: 0,
                currentFullHoursToOffPeakHoursRate: 0,
            }, {
                simulationAutoProduction: 0,
                autoProductionGoal: 0,
                autoProductionRateGoal: random(20, 80),
                simulationAutoProductionRate: random(20, 80),
                autoConsumption: 0,
                simulationAutoConsumptionRate: random(20, 80),
                maximumAnnualNetworkBillingGoalRate: 0,
                saving: 0,
                simulationSavingRate: random(20, 80),
                weekDaysHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(20, 60), random(200, 250), random(300, 350), random(150, 200), random(200, 250), random(250, 300), random(100, 180), random(30, 90), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekDaysHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 90), random(270, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 260), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(140, 180), random(180, 240), random(280, 330), random(120, 160), random(40, 90), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 100), random(40, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekendHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 80), random(260, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 70), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 280), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                production: 0,
                consumption: 0,
                purchase: 0,
                currentBilling: 0,
                newBilling: 0,
                maximumPurchase: 0,
                subscribedPowerReduction: 0,
                networkWithdrawReduction: 0,
                newFullHoursToOffPeakHoursRate: 0,
                currentFullHoursToOffPeakHoursRate: 0,
            }, {
                simulationAutoProduction: 0,
                autoProductionGoal: 0,
                autoProductionRateGoal: random(20, 80),
                simulationAutoProductionRate: random(20, 80),
                autoConsumption: 0,
                simulationAutoConsumptionRate: random(20, 80),
                maximumAnnualNetworkBillingGoalRate: 0,
                saving: 0,
                simulationSavingRate: random(20, 80),
                weekDaysHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(20, 60), random(200, 250), random(300, 350), random(150, 200), random(200, 250), random(250, 300), random(100, 180), random(30, 90), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekDaysHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 90), random(270, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 260), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(140, 180), random(180, 240), random(280, 330), random(120, 160), random(40, 90), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 100), random(40, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekendHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 80), random(260, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 70), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 280), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                production: 0,
                consumption: 0,
                purchase: 0,
                currentBilling: 0,
                newBilling: 0,
                maximumPurchase: 0,
                subscribedPowerReduction: 0,
                networkWithdrawReduction: 0,
                newFullHoursToOffPeakHoursRate: 0,
                currentFullHoursToOffPeakHoursRate: 0,
            }, {
                simulationAutoProduction: 0,
                autoProductionGoal: 0,
                autoProductionRateGoal: random(20, 80),
                simulationAutoProductionRate: random(20, 80),
                autoConsumption: 0,
                simulationAutoConsumptionRate: random(20, 80),
                maximumAnnualNetworkBillingGoalRate: 0,
                saving: 0,
                simulationSavingRate: random(20, 80),
                weekDaysHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(20, 60), random(200, 250), random(300, 350), random(150, 200), random(200, 250), random(250, 300), random(100, 180), random(30, 90), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekDaysHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 90), random(270, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 260), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(140, 180), random(180, 240), random(280, 330), random(120, 160), random(40, 90), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 100), random(40, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekendHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 80), random(260, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 70), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 280), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                production: 0,
                consumption: 0,
                purchase: 0,
                currentBilling: 0,
                newBilling: 0,
                maximumPurchase: 0,
                subscribedPowerReduction: 0,
                networkWithdrawReduction: 0,
                newFullHoursToOffPeakHoursRate: 0,
                currentFullHoursToOffPeakHoursRate: 0,
            }, {
                simulationAutoProduction: 0,
                autoProductionGoal: 0,
                autoProductionRateGoal: random(20, 80),
                simulationAutoProductionRate: random(20, 80),
                autoConsumption: 0,
                simulationAutoConsumptionRate: random(20, 80),
                maximumAnnualNetworkBillingGoalRate: 0,
                saving: 0,
                simulationSavingRate: random(20, 80),
                weekDaysHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(20, 60), random(200, 250), random(300, 350), random(150, 200), random(200, 250), random(250, 300), random(100, 180), random(30, 90), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekDaysHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 90), random(270, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 260), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(140, 180), random(180, 240), random(280, 330), random(120, 160), random(40, 90), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 100), random(40, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekendHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 80), random(260, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 70), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 280), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                production: 0,
                consumption: 0,
                purchase: 0,
                currentBilling: 0,
                newBilling: 0,
                maximumPurchase: 0,
                subscribedPowerReduction: 0,
                networkWithdrawReduction: 0,
                newFullHoursToOffPeakHoursRate: 0,
                currentFullHoursToOffPeakHoursRate: 0,
            }, {
                simulationAutoProduction: 0,
                autoProductionGoal: 0,
                autoProductionRateGoal: random(20, 80),
                simulationAutoProductionRate: random(20, 80),
                autoConsumption: 0,
                simulationAutoConsumptionRate: random(20, 80),
                maximumAnnualNetworkBillingGoalRate: 0,
                saving: 0,
                simulationSavingRate: random(20, 80),
                weekDaysHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(20, 60), random(200, 250), random(300, 350), random(150, 200), random(200, 250), random(250, 300), random(100, 180), random(30, 90), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekDaysHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 90), random(270, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 260), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(140, 180), random(180, 240), random(280, 330), random(120, 160), random(40, 90), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 100), random(40, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekendHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 80), random(260, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 70), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 280), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                production: 0,
                consumption: 0,
                purchase: 0,
                currentBilling: 0,
                newBilling: 0,
                maximumPurchase: 0,
                subscribedPowerReduction: 0,
                networkWithdrawReduction: 0,
                newFullHoursToOffPeakHoursRate: 0,
                currentFullHoursToOffPeakHoursRate: 0,
            }, {
                simulationAutoProduction: 0,
                autoProductionGoal: 0,
                autoProductionRateGoal: random(20, 80),
                simulationAutoProductionRate: random(20, 80),
                autoConsumption: 0,
                simulationAutoConsumptionRate: random(20, 80),
                maximumAnnualNetworkBillingGoalRate: 0,
                saving: 0,
                simulationSavingRate: random(20, 80),
                weekDaysHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(20, 60), random(200, 250), random(300, 350), random(150, 200), random(200, 250), random(250, 300), random(100, 180), random(30, 90), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekDaysHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 90), random(270, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 260), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(140, 180), random(180, 240), random(280, 330), random(120, 160), random(40, 90), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 100), random(40, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekendHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 80), random(260, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 70), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 280), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                production: 0,
                consumption: 0,
                purchase: 0,
                currentBilling: 0,
                newBilling: 0,
                maximumPurchase: 0,
                subscribedPowerReduction: 0,
                networkWithdrawReduction: 0,
                newFullHoursToOffPeakHoursRate: 0,
                currentFullHoursToOffPeakHoursRate: 0,
            }, {
                simulationAutoProduction: 0,
                autoProductionGoal: 0,
                autoProductionRateGoal: random(20, 80),
                simulationAutoProductionRate: random(20, 80),
                autoConsumption: 0,
                simulationAutoConsumptionRate: random(20, 80),
                maximumAnnualNetworkBillingGoalRate: 0,
                saving: 0,
                simulationSavingRate: random(20, 80),
                weekDaysHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(20, 60), random(200, 250), random(300, 350), random(150, 200), random(200, 250), random(250, 300), random(100, 180), random(30, 90), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekDaysHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 90), random(270, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 260), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(140, 180), random(180, 240), random(280, 330), random(120, 160), random(40, 90), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 100), random(40, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekendHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 80), random(260, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 70), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 280), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                production: 0,
                consumption: 0,
                purchase: 0,
                currentBilling: 0,
                newBilling: 0,
                maximumPurchase: 0,
                subscribedPowerReduction: 0,
                networkWithdrawReduction: 0,
                newFullHoursToOffPeakHoursRate: 0,
                currentFullHoursToOffPeakHoursRate: 0,
            }, {
                simulationAutoProduction: 0,
                autoProductionGoal: 0,
                autoProductionRateGoal: random(20, 80),
                simulationAutoProductionRate: random(20, 80),
                autoConsumption: 0,
                simulationAutoConsumptionRate: random(20, 80),
                maximumAnnualNetworkBillingGoalRate: 0,
                saving: 0,
                simulationSavingRate: random(20, 80),
                weekDaysHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(20, 60), random(200, 250), random(300, 350), random(150, 200), random(200, 250), random(250, 300), random(100, 180), random(30, 90), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekDaysHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 90), random(270, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 80), 0, 0, 0, 0, 0, 0, 0],
                weekDaysHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 260), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyAutoConsumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(140, 180), random(180, 240), random(280, 330), random(120, 160), random(40, 90), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyInjectedSurplus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(100, 150), random(50, 100), random(40, 80), 0, 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyPurchase: [random(130, 170), random(130, 180), random(150, 180), random(130, 160), random(140, 180), random(120, 160), random(130, 170), random(200, 240), random(230, 260), random(230, 260), random(280, 310), random(300, 330), random(180, 200), random(220, 260), random(300, 350), random(250, 300), random(230, 280), random(200, 250), random(180, 220), random(180, 200), random(160, 190), random(150, 180), random(150, 200), random(130, 180)],
                weekendHourlyProduction: [0, 0, 0, 0, 0, 0, 0, 0, 0, random(40, 80), random(260, 320), random(380, 420), random(400, 450), random(200, 250), random(100, 150), random(40, 90), random(30, 70), 0, 0, 0, 0, 0, 0, 0],
                weekendHourlyBatteryStock: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, random(50, 100), random(200, 250), random(220, 280), random(100, 150), 0, 0, 0, 0, 0, 0, 0, 0],
                production: 0,
                consumption: 0,
                purchase: 0,
                currentBilling: 0,
                newBilling: 0,
                maximumPurchase: 0,
                subscribedPowerReduction: 0,
                networkWithdrawReduction: 0,
                newFullHoursToOffPeakHoursRate: 0,
                currentFullHoursToOffPeakHoursRate: 0,
            }]
        },
        createdAt: "",
        updatedAt: ""
    },

    simulationParametersForm: {
        installedPower: 0,
        batteryCapacity: 0,
        maximumNetworkInjectionPower: 0,
        batteryTriggerThreshold: 0
    },
    loadCurvePartialForm: {
        allMonths: [],
        missingMonths: []
    },
    simulationParametersSets: {
        installedPowerSet: [],
        batteryCapacitySet: [],
        injectionPowerSet: [],
        batteryTriggerThresholdSet: []
    },
    injectionCurveOptionsForm: {
        decimalSeparator: "",
    }

}

function random(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

