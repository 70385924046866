export default {
  initForms,
  initCustomerForm,
  initConsumptionCurveForm,
  initLoadCurvePartialForm,
  initSimulationGoalsForm,
  initScenarioForm,
  initSimulationParametersForm,
  initSimulationForm,
  isFormUpdated,
  createCookie,
  readCookie,
  eraseCookie,
  getErrorMessage,
}

function getErrorMessage(errorResponse:any) {

  if (errorResponse.response) {
    return errorResponse.response.data.message
  } else {
    return errorResponse.message
  }
}

function createCookie(name: string,value: string, days: number) {
  var expires = ""

  if (days) {
    var date = new Date();
    date.setTime(date.getTime()+(days*24*60*60*1000));

    expires = "; expires="+date.toUTCString();
  }

  document.cookie = name+"="+value+expires+"; path=/";
}

function readCookie(name: string) {
  const nameEQ = name + "="
  const ca = document.cookie.split(';')

  const cookie = ca.find(cookie => cookie.trim().startsWith(nameEQ))
  if (cookie) {
    return cookie.split("=")[1]
  } else {
    return null
  }
}

function eraseCookie(name: string) {
  createCookie(name,"",-1);
}

function isFormUpdated(oldForm: any, newForm: any) {
  var updated = false;

  if (newForm && oldForm && Object.keys(oldForm).length > 0 && Object.keys(newForm).length > 0) {
    for (const key of Object.keys(newForm)) {
      if (JSON.stringify(newForm[key]) != JSON.stringify(oldForm[key])) {
        updated = true
      }
    }
  } else {
    updated = true
  }
  return updated
}

function initForms(form:any, type:string) {
  if (type != 'creation') {
    form = checkForm(form)
  }

  form.id = -1
  form.customerForm = initCustomerForm(form.customerForm)
  form.simulationParametersForm = initSimulationParametersForm(form.simulationParametersForm)
  return form
}

function checkForm(form: any) {
  if (!form) {
    form = {}
  }
  return form
}

function initCustomerForm(form:any) {
  form = checkForm(form)

  if (!form.customerIdentityForm || !form.electricalIdentityForm || !form.projectParametersForm || !form.projectIdentityForm || !form.userIdentificationForm || !form.consumptionCurveForm || !form.simulationGoalsForm || !form.scenarioForm) {
    form = {
      customerIdentityForm: {},
      electricalIdentityForm: {},
      projectParametersForm: {},
      projectIdentityForm: {},
      userIdentificationForm: {},
      consumptionCurveForm: {},
      simulationGoalsForm: {},
      scenarioForm: {},
      simulationForm: {},
    }
  }

  form.customerIdentityForm = initCustomerIdentityForm(form.customerIdentityForm)
  form.electricalIdentityForm = initElectricalIdentityForm(form.electricalIdentityForm)
  form.projectParametersForm = initProjectParametersForm(form.projectParametersForm)
  form.projectIdentityForm = initProjectIdentityForm(form.projectIdentityForm)
  form.userIdentificationForm = initUserIdentificationForm(form.userIdentificationForm)
  form.consumptionCurveForm = initConsumptionCurveForm(form.consumptionCurveForm)
  form.simulationGoalsForm = initSimulationGoalsForm(form.simulationGoalsForm)
  form.scenarioForm = initScenarioForm(form.scenarioForm)
  form.simulationForm = initSimulationParametersForm(form.simulationParametersForm)

  return form
}

function initCustomerIdentityForm(form:any) {
  form.id = null
  form.companyName = ""
  form.logo = ""
  form.address = ""
  form.addressComplement = ""
  form.postalCode = ""
  form.city = ""
  form.country = ""

  return form
}

function initElectricalIdentityForm(form:any) {

  form.seasonReferentialFormatted = {}

  form.id = null
  form.subscription = ""
  form.subscribedPower = 0
  form.uniqueTariff = false
  form.purchaseTariff = null
  form.offPeakHours = [{startOffPeakHours: "", endOffPeakHours: ""}]
  form.winterFullHoursTariff = 0
  form.winterOffHoursTariff = 0
  form.summerFullHoursTariff = 0
  form.summerOffHoursTariff = 0
  form.seasonReferentialFormatted.summerStartDate = ""
  form.seasonReferentialFormatted.winterStartDate = ""
  form.seasonReferentialFormatted.id = null
  form.energyTaxes = 0

  return form
}

function initProjectParametersForm(form:any) {
  form.orientationReferential = {}

  form.id = null
  form.orientationReferential.id = 1
  form.orientationReferential.label = "Sud"
  form.minimumPowerPeak = 0
  form.maximumPowerPeak = 0
  form.maximumStorageCapacity = 0
  form.batteryPerformanceRate = 0
  form.maximumChargeDischargePower = 0
  form.maximumInjectionPower = 0
  form.producible = 0
  form.surplusValuationTariff = 0
  form.averageCarbonContentPerKWh = 0
  form.weekStartDay = 1
  form.weekendStartDay = 6
  form.description = ""

  return form
}

function initProjectIdentityForm(form:any) {
  form.id = null
  form.projectStatus = ""
  form.projectManager = ""

  return form
}

function initUserIdentificationForm(form:any) {
  form.id = null
  form.email = ""
  form.password = ""

  return form
}

function initLoadCurvePartialForm(form:any) {
  form = checkForm(form)

  form.allMonths = []
  form.missingMonths = []

  return form
}

function initConsumptionCurveForm(form:any) {
  form = checkForm(form)
  form.id = null

  form.annualConsumption = 0
  form.fullHoursToOffPeakHoursRate = 0
  form.averageWithdrawPower = 0
  form.maximumWithdrawPower = 0
  form.annualBilling = 0
  form.monthlyConsumptions = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  form.createdAt = ""
  form.updatedAt = ""
  form.isPartial = false

  return form
}

function initSimulationGoalsForm(form:any) {
  form = checkForm(form)
  form.id = null

  form.minimumAutoProductionRate = 0
  form.maximumWithdrawPower = 0
  form.maximumAnnualNetworkBilling = 0

  return form
}

// function initScenarioForm(form:any) {
//   form = checkForm(form)
//
//   form.simulationParametersSets.installedPowerSet = []
//   form.simulationParametersSets.batteryCapacitySet = []
//   form.simulationParametersSets.injectionPowerSet = []
//   form.simulationParametersSets.batteryTriggerThresholdSet = []
//   form.cells = [{
//     col: 0,
//     row: 0,
//     autoProduction: 0,
//     maximumWithdrawPower: 0,
//     billingReductionRate: 0
//   }]
//
//   return form
// }

function initScenarioForm(form:any) {
  form = checkForm(form)

  form.columns = []
  form.indexes = []
  form.scenarios = [{
    name: "",
    values: [[null]]
  },{
    name: "",
    values: [[null]]
  },{
    name: "",
    values: [[null]]
  }]

  return form
}

function initSimulationParametersForm(form:any) {
  form = checkForm(form)

  form.installedPower = 0
  form.batteryCapacity = 0
  form.njectionPower = 0
  form.batteryTriggerThreshold = 0

  return form
}

function initSimulationForm(form:any) {
  form = checkForm(form)

  if (!form.annualSimulation || !form.monthlySimulation) {
    form = {
      annualSimulation: {},
      monthlySimulation: []
    }
  }

  form.annualSimulation = initSimulationFormAnnualView(form.annualSimulation)
  form.monthlySimulation = initSimulationFormMonthlyViews(form.monthlySimulation)

  return form
}

function initSimulationFormAnnualView(form:any) {
  form.simulationAutoProduction = 0
  form.autoProductionGoal = 0
  form.autoProductionRateGoal = 0
  form.simulationAutoProductionRate = 0
  form.autoConsumption = 0
  form.simulationAutoConsumptionRate = 0
  form.maximumAnnualNetworkBillingGoalRate = 0
  form.saving = 0
  form.injectedEnergyValuation = 0
  form.simulationSavingRate = 0
  form.productionSurplus = 0
  form.resalePerYear = 0
  form.consumption = 0
  form.monthlyConsumptions = []
  form.purchase= 0
  form.monthlyPurchases = []
  form.subscribedPowerReduction = 0
  form.networkWithdrawReduction = 0
  form.newAverageWithdrawnPower = 0
  form.currentAverageWithdrawnPower = 0
  form.batteryCyclesNumber = 0
  form.newFullHoursToOffPeakHoursRate = 0
  form.currentFullHoursToOffPeakHoursRate = 0
  form.powerLimitedLostEnergy = 0
  form.carbonOffsetting = 0
  form.newBilling = 0
  form.currentBilling = 0
  form.production = 0
  form.maximumPurchase = 0
  form.pictureYear = ''
  form.pictureMonth = ''
  form.pictureMonthlySimulationDay = ''
  form.pictureMonthlySimulationEnnd = ''

  return form
}

function initSimulationFormMonthlyViews(form: any) {
  form = []
  form.length = 12
  form.forEach((monthlySimulation:any) => monthlySimulation = initSimulationFormMonthlyView(monthlySimulation))

  return form
}

function initSimulationFormMonthlyView(form:any) {
  form.simulationAutoProduction = 0
  form.autoProductionGoal = 0
  form.autoProductionRateGoal = 0
  form.simulationAutoProductionRate = 0
  form.autoConsumption = 0
  form.goalAutoConsumptionRate = 0
  form.simulationAutoConsumptionRate = 0
  form.maximumAnnualNetworkBillingGoalRate = 0
  form.saving = 0
  form.injectedEnergyValuation = 0
  form.goalSavingRate = 0
  form.simulationSavingRate = 0
  form.weekDaysHourlyAutoConsumption = []
  form.weekDaysHourlyInjectedSurplus = []
  form.weekDaysHourlyPurchase = []
  form.weekDaysHourlyProduction = []
  form.weekDaysHourlyBatteryStock = []
  form.weekendHourlyAutoConsumption = []
  form.weekendHourlyInjectedSurplus = []
  form.weekendHourlyPurchase = []
  form.weekendHourlyProduction = []
  form.weekendHourlyBatteryStock = []
  form.production = 0
  form.consumption = 0
  form.purchase = 0
  form.currentBilling = 0
  form.newBilling = 0
  form.maximumPurchase = 0
  form.subscribedPowerReduction = 0
  form.networkWithdrawReduction = 0
  form.newFullHoursToOffPeakHoursRate = 0
  form.currentFullHoursToOffPeakHoursRate = 0

  return form
}
