<template>
  <div class="overlay_PDF">
    <span>RETIX export</span>
    <div>
      <img id="chart-picture" src="imgSrc" alt="chart" style="width: 50%"/>
    </div>
  </div>
</template>

<script>
export default {
  props:['imgSrc'],
  mounted() {
    document.getElementById('chart-picture').src = this.imgSrc
    setTimeout(() => {
      window.print()
      this.$parent.page = 1
      }, 1000)
  }
}
</script>