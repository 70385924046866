import { customerService } from '../../services/customer';
import { consumptionCurveService } from '@/services/consumptionCurve';
import { scenarioService } from '@/services/scenario';
import { simulationService } from '@/services/simulation';

const namespaced = true;

export default {
	namespaced,
	state: {
		all: {},
		create: {},
		update: {},
		delete: {},
		customer: {},
		importSheet: {},
		//importPartialSheet: {},
		//importMissingMonths: {},
		scenarios: {},
		simulation: {},
		//completePartialConsumptions: {},
		actionState: null,
		injectionCurve: {},
	},
	getters: {
		getAll: (state: any) => {
			return state.all;
		},
		getById: (state: any) => {
			return state.all;
		},
		getCreated: (state: any) => {
			return state.create;
		},
		getUpdated: (state: any) => {
			return state.update;
		},
		getDeleted: (state: any) => {
			return state.delete;
		},
		get: (state: any) => {
			return state.customer;
		},
		getImportedSheet: (state: any) => {
			return state.importSheet;
		},
		// getImportedPartialSheet: (state: any) => {
		// 	return state.importPartialSheet;
		// },
		// getImportedMissingMonths: (state: any) => {
		// 	return state.importMissingMonths;
		// },
		// getCompletePartialConsumptions: (state: any) => {
		// 	return state.completePartialConsumptions;
		// },
		getScenarios: (state: any) => {
			return state.scenarios;
		},
		getSimulation: (state: any) => {
			return state.simulation;
		},
		getInjectionCurve: (state: any) => {
			return state.injectionCurve;
		},
	},
	actions: {
		async getScenarios(
			{ commit, state }: { commit: any; state: any },
			params: any,
		) {
			try {
				const customer = await scenarioService.get({ params });
				commit('scenariosSuccess', customer);
			} catch (error) {
				commit('scenariosFailure', error);
			}
		},
		async getSimulation(
			{ commit, state }: { commit: any; state: any },
			params: any,
		) {
			try {
				const customer = await simulationService.getSimulation({ params });
				commit('simulationSuccess', customer);
			} catch (error) {
				commit('simulationFailure', error);
			}
		},
		async getInjectionCurve(
			{ commit, state }: { commit: any; state: any },
			params: any,
		) {
			try {
				const injectionCurve = await simulationService.getInjectionCurve({ params });
				commit('injectionCurveSuccess', injectionCurve);
			} catch (error) {
				commit('injectionCurveFailure', error);
			}
		},
		async importSheet(
			{ commit, state }: { commit: any; state: any },
			params: any,
		) {
			try {
				const customer = await consumptionCurveService.update({ params });
				commit('importSheetSuccess', customer);
			} catch (error) {
				commit('importSheetFailure', error);
			}
		},
		// async importPartialSheet({ commit,  state } : { commit:any, state:any }, params:any ) {
		//   try {
		//     const customer = await customerService.importPartialSheet({ params })
		//     commit('importPartialSheetSuccess', customer)
		//   } catch (error) {
		//     commit('importPartialSheetFailure', error)
		//   }
		// },
		// async importMissingMonths({ commit, state } : { commit: any, state: any }, params:any ) {
		//   try {
		//     const customer = await customerService.importMissingMonths({ params })
		//     commit('importMissingMonthsSuccess', customer)
		//   } catch (error) {
		//     commit('importMissingMonthsFailuer', error)
		//   }
		// },
		async getAll({ commit, state }: { commit: any; state: any }) {
			try {
				const customers = await customerService.getAllSimpleUsers();
				commit('getAllSuccess', customers);
			} catch (error) {
				commit('getAllFailure', error);
			}
		},
		async getById({ commit, state }: {commit: any; state: any}, params: any) {
			try {
				const customers = await customerService.getByUserId( { params });
				commit('getByIdSuccess', customers);
			} catch (error) {
				commit('getByIdFailure', error);
			}
		},
		async create({ commit, state }: { commit: any; state: any }, params: any) {
			try {
				const customer = await customerService.create({ params });
				commit('createSuccess', customer);
			} catch (error) {
				commit('createFailure', error);
			}
		},
		async update({ commit, state }: { commit: any; state: any }, params: any) {
			try {
				const customer = await customerService.update({ params });
				commit('updateSuccess', customer);
			} catch (error) {
				commit('updateFailure', error);
			}
		},
		async delete({ commit, state }: { commit: any; state: any }, params: any) {
			try {
				const customer = await customerService.remove({ params });
				commit('deleteSuccess', customer);
			} catch (error) {
				commit('deleteFailure', error);
			}
		},
		async get({ commit, state }: { commit: any; state: any }, params: any) {
			try {
				const customer = await customerService.get({ params });
				commit('getSuccess', customer);
			} catch (error) {
				commit('getFailure', error);
			}
		},
		//   async completePartialConsumptions({ commit, state } : {commit:any, state:any}, params:any) {
		//     try {
		//       const customer = await customerService.completePartialConsumptions({ params })
		//       commit('getCompletePartialConsumptionsSuccess', customer)
		//     } catch (error) {
		//       commit('getCompletePartialConsumptionsFailure', error)
		//     }
		//   }
	},
	mutations: {
		importSheetSuccess(state: any, customer: any) {
			state.importSheet = customer.data;
		},
		importSheetFailure(state: any, error: any) {
			state.importSheet = { error };
		},
		// importPartialSheetSuccess(state: any, customer: any) {
		// 	state.importPartialSheet = customer.data;
		// },
		// importPartialSheetFailure(state: any, error: any) {
		// 	state.importPartialSheet = { error };
		// },
		// importMissingMonthsSuccess(state: any, customer: any) {
		// 	state.importMissingMonths = customer.data;
		// },
		// importMissingMonthsFailure(state: any, error: any) {
		// 	state.importMissingMonths = { error };
		// },
		getAllSuccess(state: any, customer: any) {
			state.all = customer.data;
		},
		getAllFailure(state: any, error: any) {
			state.all = { error };
		},
		getByIdSuccess(state: any, customer: any) {
			state.all = customer.data;
		},
		getByIdFailure(state: any, error: any) {
			state.all = { error };
		},
		createSuccess(state: any, customer: any) {
			state.create = customer.data;
		},
		createFailure(state: any, error: any) {
			state.create = { error };
		},
		updateSuccess(state: any, customer: any) {
			state.update = customer.data;
		},
		updateFailure(state: any, error: any) {
			state.update = { error };
		},
		deleteSuccess(state: any, customer: any) {
			state.delete = customer.data;
		},
		deleteFailure(state: any, error: any) {
			state.delete = { error };
		},
		getSuccess(state: any, customer: any) {
			state.customer = customer.data;
		},
		getFailure(state: any, error: any) {
			state.customer = { error };
		},
		scenariosSuccess(state: any, customer: any) {
			state.scenarios = customer.data;
		},
		scenariosFailure(state: any, error: any) {
			state.scenarios = { error };
		},
		simulationSuccess(state: any, customer: any) {
			state.simulation = customer.data;
		},
		simulationFailure(state: any, error: any) {
			state.simulation = { error };
		},
		injectionCurveSuccess(state: any, customer: any) {
			state.injectionCurve = customer.data;
		},
		injectionCurveFailure(state: any, error: any) {
			state.injectionCurve = { error };
		},
		// getCompletePartialConsumptionsSuccess(state: any, customer: any) {
		// 	state.completePartialConsumptions = customer.data;
		// },
		// getCompletePartialConsumptionsFailure(state: any, error: any) {
		// 	state.completePartialConsumptions = { error };
		// },
	},
};
