<template>
  <div>
      <LoadCurveSubPage01 v-if="page==1" />
      <LoadCurveSubPage02 v-if="page==2" />
      <LoadCurveSubPage03 v-if="uploading" />
      <SpinnerModal :message="'Calculating missing months.'" v-if="displaySpinner" />
      <PartialModal v-if="showPartial" />
      <ModalError :message="errorMessage" :title="errorTitle" />

      <div class="row">
        <div class="col-12">
          <div class="cta_prev_next">
            <a @click="goToWithoutCheck('/information')" class="cta_prev">
            <span class="icon">
              <img src="../assets/images/arrow_cta_prev.svg" border="0" alt="Arrow prev" />
            </span>
            </a>
            <a @click="goToWithoutCheck('/goal')" class="cta_next" id="reactive_cta_next">
            <span class="icon">
              <img src="../assets/images/arrow_cta_next.svg" border="0" alt="Arrow prev" />
            </span>
            </a>
          </div>

          <div class="content_retix">
            <div class="container-retix">
              <!-- -->
              <div class="row">
                <div class="col-12">
                  <h1 v-if="!forms.customerForm.customerIdentityForm.companyName">Nouveau client</h1>
                  <h1
                      v-if="forms.customerForm.customerIdentityForm.companyName"
                  >{{ forms.customerForm.customerIdentityForm.companyName }}</h1>
                  <span class="step_customer">( Étape 2/4 )</span>
                </div>
              </div>
              <!-- -->
              <div class="row">
                <div class="col-12">
                  <div class="timeline">
                    <div class="row">
                      <div
                          id="wizzard_informations"
                          class="col done"
                          @click="goTo('/information')"
                      >
                        <div class="dot_timeline">
                          <span class="minidot_timeline"></span>
                        </div>
                        <span class="txt_timeline">Informations</span>
                      </div>
                      <div id="wizzard_load" class="col actif" @click="goTo('/load_curve')">
                        <div class="dot_timeline">
                          <span class="minidot_timeline"></span>
                        </div>
                        <span class="txt_timeline">Courbe de charge</span>
                      </div>
                      <div id="wizzard_goals" class="col actif" @click="goTo('/goal')">
                        <div class="dot_timeline">
                          <span class="minidot_timeline"></span>
                        </div>
                        <span class="txt_timeline">Objectifs</span>
                      </div>
                      <div id="wizzard_scenarii" class="col" @click="goTo('/scenario')">
                        <div class="dot_timeline">
                          <span class="minidot_timeline"></span>
                        </div>
                        <span class="txt_timeline">Scenarii</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- -->

              <div class="content_customer">
                <div class="bloc_courbe_charge">
                  <img src="../assets/images/icon_courbe_de_charge.svg" alt="courbe de charge" />
                  <h3>Veuillez télécharger la courbe de charge</h3>
                  <div class="bloc_cta">
                    <!-- -->
                    <div
                        class="load_more margin_right_10"
                        style="margin-bottom:0"
                        @click="loadCompleteFile()"
                    >
                      <div class="load_more_mask">
                      <span class="icon">
                        <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="-63 157 541 481"
                            style="enable-background:new -63 157 541 481;"
                            xml:space="preserve"
                        >
                          <g>
                            <path
                                class="st1"
                                d="M450,480.9c-7.2,0-13,5.8-13,13v49.8c0,28.7-23.4,52.1-52.1,52.1H29.2c-28.7,0-52.1-23.4-52.1-52.1V494
        c0-7.2-5.8-13-13-13s-13,5.8-13,13v49.8c0,43.1,35.1,78.2,78.2,78.2h355.6c43.1,0,78.2-35.1,78.2-78.2V494
        C463,486.8,457.2,480.9,450,480.9z"
                            />
                            <path
                                class="st1"
                                d="M384.8,630.5H29.2c-47.8,0-86.7-38.9-86.7-86.7V494c0-11.9,9.7-21.5,21.5-21.5c11.9,0,21.5,9.7,21.5,21.5v49.8
        c0,24.1,19.6,43.6,43.6,43.6h355.6c24.1,0,43.6-19.6,43.6-43.6V494c0-11.9,9.7-21.5,21.5-21.5c11.9,0,21.5,9.7,21.5,21.5v49.8
        C471.5,591.6,432.6,630.5,384.8,630.5z M-36,489.4c-2.5,0-4.5,2-4.5,4.5v49.8c0,38.4,31.3,69.7,69.7,69.7h355.6
        c38.4,0,69.7-31.3,69.7-69.7V494c0-2.5-2-4.5-4.5-4.5s-4.5,2-4.5,4.5v49.8c0,33.4-27.2,60.6-60.6,60.6H29.2
        c-33.4,0-60.6-27.2-60.6-60.6V494C-31.4,491.5-33.5,489.4-36,489.4z"
                            />
                          </g>
                          <g>
                            <path
                                class="st1"
                                d="M93.5,297.3L194,207.5v252.6c0,7.2,5.8,13,13,13c7.2,0,13-5.8,13-13V207.5l100.5,89.8c2.5,2.2,5.6,3.3,8.7,3.3
        c3.6,0,7.2-1.5,9.7-4.4c4.8-5.4,4.3-13.6-1-18.4l-109.2-97.5c-12.4-11.1-31-11.1-43.4,0L76.1,277.8c-5.4,4.8-5.8,13-1,18.4
        C79.9,301.6,88.1,302.1,93.5,297.3z"
                            />
                            <path
                                class="st1"
                                d="M207,481.7c-11.9,0-21.5-9.7-21.5-21.5V226.5l-86.3,77.1c-8.9,7.9-22.5,7.1-30.4-1.7
        c-3.8-4.3-5.8-9.8-5.4-15.6c0.3-5.7,2.9-11,7.2-14.8L179.6,174c15.6-13.9,39.1-13.9,54.7,0l109.2,97.5c8.9,7.9,9.6,21.6,1.7,30.4
        c-4.1,4.6-9.9,7.2-16.1,7.2c-5.3,0-10.4-1.9-14.3-5.5l-86.3-77.1v233.6C228.5,472,218.9,481.7,207,481.7z M202.5,188.5v271.6
        c0,2.5,2,4.5,4.5,4.5s4.5-2,4.5-4.5V188.5l114.6,102.4c1.1,1,2.2,1.2,3,1.2c0.8,0,2.2-0.2,3.4-1.5c1.7-1.9,1.5-4.7-0.4-6.4
        L223,186.6c-9.1-8.2-22.9-8.2-32.1,0L81.8,284.2c-0.9,0.8-1.4,1.9-1.5,3.1c-0.1,1.2,0.3,2.4,1.1,3.3c1.7,1.9,4.5,2,6.4,0.4
        L202.5,188.5z"
                            />
                          </g>
                        </svg>
                      </span>
                        <span class="txt">COURBE DE CHARGE COMPLÈTE</span>
                      </div>
                    </div>
                    <!-- -->
<!--                    <div class="load_more margin_left_10" @click="loadNonCompleteFile()">-->
<!--                      <div class="load_more_mask">-->
<!--                      <span class="icon">-->
<!--                        <svg-->
<!--                            version="1.1"-->
<!--                            xmlns="http://www.w3.org/2000/svg"-->
<!--                            xmlns:xlink="http://www.w3.org/1999/xlink"-->
<!--                            x="0px"-->
<!--                            y="0px"-->
<!--                            viewBox="-63 157 541 481"-->
<!--                            style="enable-background:new -63 157 541 481;"-->
<!--                            xml:space="preserve"-->
<!--                        >-->
<!--                          <g>-->
<!--                            <path-->
<!--                                class="st1"-->
<!--                                d="M450,480.9c-7.2,0-13,5.8-13,13v49.8c0,28.7-23.4,52.1-52.1,52.1H29.2c-28.7,0-52.1-23.4-52.1-52.1V494-->
<!--        c0-7.2-5.8-13-13-13s-13,5.8-13,13v49.8c0,43.1,35.1,78.2,78.2,78.2h355.6c43.1,0,78.2-35.1,78.2-78.2V494-->
<!--        C463,486.8,457.2,480.9,450,480.9z"-->
<!--                            />-->
<!--                            <path-->
<!--                                class="st1"-->
<!--                                d="M384.8,630.5H29.2c-47.8,0-86.7-38.9-86.7-86.7V494c0-11.9,9.7-21.5,21.5-21.5c11.9,0,21.5,9.7,21.5,21.5v49.8-->
<!--        c0,24.1,19.6,43.6,43.6,43.6h355.6c24.1,0,43.6-19.6,43.6-43.6V494c0-11.9,9.7-21.5,21.5-21.5c11.9,0,21.5,9.7,21.5,21.5v49.8-->
<!--        C471.5,591.6,432.6,630.5,384.8,630.5z M-36,489.4c-2.5,0-4.5,2-4.5,4.5v49.8c0,38.4,31.3,69.7,69.7,69.7h355.6-->
<!--        c38.4,0,69.7-31.3,69.7-69.7V494c0-2.5-2-4.5-4.5-4.5s-4.5,2-4.5,4.5v49.8c0,33.4-27.2,60.6-60.6,60.6H29.2-->
<!--        c-33.4,0-60.6-27.2-60.6-60.6V494C-31.4,491.5-33.5,489.4-36,489.4z"-->
<!--                            />-->
<!--                          </g>-->
<!--                          <g>-->
<!--                            <path-->
<!--                                class="st1"-->
<!--                                d="M93.5,297.3L194,207.5v252.6c0,7.2,5.8,13,13,13c7.2,0,13-5.8,13-13V207.5l100.5,89.8c2.5,2.2,5.6,3.3,8.7,3.3-->
<!--        c3.6,0,7.2-1.5,9.7-4.4c4.8-5.4,4.3-13.6-1-18.4l-109.2-97.5c-12.4-11.1-31-11.1-43.4,0L76.1,277.8c-5.4,4.8-5.8,13-1,18.4-->
<!--        C79.9,301.6,88.1,302.1,93.5,297.3z"-->
<!--                            />-->
<!--                            <path-->
<!--                                class="st1"-->
<!--                                d="M207,481.7c-11.9,0-21.5-9.7-21.5-21.5V226.5l-86.3,77.1c-8.9,7.9-22.5,7.1-30.4-1.7-->
<!--        c-3.8-4.3-5.8-9.8-5.4-15.6c0.3-5.7,2.9-11,7.2-14.8L179.6,174c15.6-13.9,39.1-13.9,54.7,0l109.2,97.5c8.9,7.9,9.6,21.6,1.7,30.4-->
<!--        c-4.1,4.6-9.9,7.2-16.1,7.2c-5.3,0-10.4-1.9-14.3-5.5l-86.3-77.1v233.6C228.5,472,218.9,481.7,207,481.7z M202.5,188.5v271.6-->
<!--        c0,2.5,2,4.5,4.5,4.5s4.5-2,4.5-4.5V188.5l114.6,102.4c1.1,1,2.2,1.2,3,1.2c0.8,0,2.2-0.2,3.4-1.5c1.7-1.9,1.5-4.7-0.4-6.4-->
<!--        L223,186.6c-9.1-8.2-22.9-8.2-32.1,0L81.8,284.2c-0.9,0.8-1.4,1.9-1.5,3.1c-0.1,1.2,0.3,2.4,1.1,3.3c1.7,1.9,4.5,2,6.4,0.4-->
<!--        L202.5,188.5z"-->
<!--                            />-->
<!--                          </g>-->
<!--                        </svg>-->
<!--                      </span>-->
<!--                        <span class="txt">COURBE DE CHARGE PARTIELLE</span>-->
<!--                      </div>-->
<!--                    </div>-->
                    <!-- -->
                  </div>
                </div>
              </div>
              <input
                  class="inputfile"
                  type="file"
                  id="file"
                  @change="parsed"
                  accept=".csv"
                  style="display:none"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import LoadCurveSubPage01 from "../components/LoadCurve_SubPage1";
import LoadCurveSubPage02 from "../components/LoadCurve_SubPage2";
import LoadCurveSubPage03 from "../components/LoadCurve_SubPage3";
import SpinnerModal from "../components/SpinnerModal";
import ModalError from "../components/ModalError";
import PartialModal from "../components/PartialModal";
import validation from "../utils/validation";
import util from "../utils/util";
import customerUtil from "../utils/customer";
import { mapGetters } from "vuex";

export default {
  mounted() {
    if (validation.isLoadCurveAnalyseValid(this.forms.customerForm.consumptionCurveForm)) {
      document
          .getElementById("reactive_cta_next")
          .classList.add("cta_next_valid");
    } else {
      document
          .getElementById("reactive_cta_next")
          .classList.remove("cta_next_valid");
    }
    this.updateWizard();
  },
  components: {
    LoadCurveSubPage01,
    LoadCurveSubPage02,
    LoadCurveSubPage03,
    ModalError,
    PartialModal,
    SpinnerModal
  },
  computed: {
    ...mapGetters({
      getPartial: "customer/getImportedPartialSheet"
    })
  },
  data() {
    return {
      showPartial: false,
      displaySpinner: false,
      parsedResult: [],
      file: null,
      loadComplete: true,
      page: 0,
      uploading: false,
      errorMessage: "",
      errorTitle: "",
      months: [
        { id: 1, name: "Janvier" },
        { id: 2, name: "Février" },
        { id: 3, name: "Mars" },
        { id: 4, name: "Avril" },
        { id: 5, name: "Mai" },
        { id: 6, name: "Juin" },
        { id: 7, name: "Juillet" },
        { id: 8, name: "Août" },
        { id: 9, name: "Septembre" },
        { id: 10, name: "Octobre" },
        { id: 11, name: "Novembre" },
        { id: 12, name: "Décembre" }
      ]
    };
  },
  methods: {
    updateWizard: function() {
      document.getElementById("wizzard_informations").classList.remove("actif");
      document.getElementById("wizzard_goals").classList.remove("actif");
      document.getElementById("wizzard_load").classList.add("actif");
      document.getElementById("wizzard_scenarii").classList.remove("actif");
    },
    goTo: function(route) {
      if (route === "/load_curve" && validation.isLoadCurveAnalyseValid(this.forms.customerForm.consumptionCurveForm)) {
        this.$router.push(route);
      }
      if (route === "/scenario" && forms.customerForm.scenariosForm) {
        this.$router.push(route);
      }
      if (route === "/goal" && forms.customerForm.consumptionCurveForm.annualConsumption) {
        this.$router.push(route);
      }
      if (route === "/information") {
        this.$router.push(route);
      }
    },
    async selectCustomer() {
      const id = window.localStorage.getItem("customerId")
          ? JSON.parse(window.localStorage.getItem("customerId"))
          : -1;
      if ((!forms.id || forms.id < 0) && id >= 0) {
        await this.$store.dispatch("customer/get", { id });
        const response = this.$store.getters["customer/get"];

        if (response.error) {
          this.errorTitle = "Get error";
          this.errorMessage = util.getErrorMessage(response.error);
          this.$bvModal.show("error-modal");
        } else {
          customerUtil.updateCustomerForm(response, forms, id);
        }
      }
    },
    loadCompleteFile: function() {
      this.loadComplete = true;
      document.getElementById("file").click();
    },
    loadNonCompleteFile: function() {
      this.loadComplete = false;
      document.getElementById("file").click();
    },
    async parsed(event) {
      if (!this.loadComplete) {
        this.displaySpinner = true;
      }
      this.file = event.target.files[0];
      event.srcElement.value = "";
      event.target.value = "";

      var formData = new FormData();
      formData.append("file", this.file);

      const objectToUpload = {
        customerId: forms.id,
        formData
      };

      if (this.loadComplete) {
        this.uploading = true;

        await this.$store.dispatch("customer/importSheet", objectToUpload);
        const customerResponse = await this.$store.getters[
            "customer/getImportedSheet"
            ];

        if (customerResponse.error) {
          this.errorTitle = "Import sheet error";
          this.errorMessage = util.getErrorMessage(customerResponse.error);
          this.$bvModal.show("error-modal");
        } else {
          forms.customerForm.consumptionCurveForm = customerResponse.consumptionCurve;

          this.$router.push({ name: "goal" });
          this.uploading = false;
        }
      } else {
        await this.$store.dispatch(
            "customer/importPartialSheet",
            objectToUpload
        );
        let customerResponse = this.getPartial;

        if (customerResponse.error) {
          this.errorTitle = "Import partial sheet error";
          this.errorMessage = util.getErrorMessage(customerResponse.error);
          this.$bvModal.show("error-modal");
        } else {
          this.showPartial = true;
          this.displaySpinner = false;
        }
        this.displaySpinner = false;
      }
      this.displaySpinner = false;
    },
    async loadMissingMonth() {
      this.uploading = true;

      const objectToUpload = {
        customerId: forms.id,
        months: {
          referenceMonth: this.months.find(
              month => month.name === forms.customerForm.consumptionCurveForm.referenceMonth
          ).id,
          missingMonths: forms.customerForm.consumptionCurveForm.months.map(month => {
            return { id: month.id, value: month.value };
          })
        }
      };

      await this.$store.dispatch(
          "customer/importMissingMonths",
          objectToUpload
      );
      const customerResponse = await this.$store.getters[
          "customer/getImportedMissingMonths"
          ];

      if (customerResponse.error) {
        this.errorTitle = "Import sheet error";
        this.errorMessage = util.getErrorMessage(customerResponse.error);
        this.$bvModal.show("error-modal");
      } else {
        forms.customerForm.consumptionCurveForm = customerResponse.consumptionCurve;

        this.$router.push({ name: "goal" });
        this.uploading = false;
      }
    },
      goToWithoutCheck: function (route) {
          if (route === "/load_curve" && forms.customerForm.consumptionCurveForm) {
              this.$router.push(route);
          }
          if (route === "/scenario" && forms.customerForm.scenariosForm) {
              this.$router.push(route);
          }
          if (route === "/goal" && forms.customerForm.consumptionCurveForm.annualConsumption) {
              this.$router.push(route);
          }
          if (route === "/information") {
              this.$router.push(route);
          }
      }
  }
};
</script>
