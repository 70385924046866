<template>
  <li class="col">
    <div class="bloc_content">
      <h2>Identité du client</h2>
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group id="client-picture-group" class="picture">
          <span class="image">
            <img
              id="client-picture-logo"
              :src="forms.customerForm.customerIdentityForm.logo"
              alt
              border="0"
              @load="changeCSS()"
              class="logo"
            />
          </span>
          <span id="file-select-button" class="browse">
            <img
              src="../assets/images/icon_upload.svg"
              border="0"
              alt="icon upload"
              @click="clickLoad"
            />
          </span>
        </b-form-group>

        <input
          class="inputfile"
          type="file"
          id="pictureFile"
          @change="loadPicture"
          accept="image/*"
          style="display: none"
        />

        <b-form-group id="client-input-group-company-name" class="bloc_form">
          <label>Raison sociale</label>
          <b-form-input
            id="client-input-company-name"
            v-model="forms.customerForm.customerIdentityForm.companyName"
            required
            class="input_form"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="client-input-group-address" class="bloc_form">
          <label>Numéro et nom de la rue</label>
          <b-form-input
            id="client-input-address"
            v-model="forms.customerForm.customerIdentityForm.address"
            required
            class="input_form"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="client-input-group-address2" class="bloc_form">
          <label>
            Complément d'adresse
            <i>(facultatif)</i>
          </label>
          <b-form-input
            id="client-input-address2"
            v-model="forms.customerForm.customerIdentityForm.addressComplement"
            class="input_form"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="client-input-group-CP" class="bloc_form">
          <label>Code postal</label>
          <b-form-input
            id="client-input-CP"
            v-model="forms.customerForm.customerIdentityForm.postalCode"
            type="number"
            step="any"
            required
            class="input_form"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="client-input-group-city" class="bloc_form">
          <label>Ville</label>
          <b-form-input
            id="client-input-city"
            v-model="forms.customerForm.customerIdentityForm.city"
            required
            class="input_form"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="client-input-group-country" class="bloc_form">
          <label>Pays</label>
          <b-form-input
            id="client-input-country"
            v-model="forms.customerForm.customerIdentityForm.country"
            required
            class="input_form"
          ></b-form-input>
        </b-form-group>
      </b-form>
    </div>
  </li>
</template>

<script>
import Vue from 'vue';
export default {
  data() {
    return {
      show: true,
      file: null,
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
    },
    onReset(evt) {
      evt.preventDefault();
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    clickLoad() {
      document.getElementById("pictureFile").click();
    },
    loadPicture(event) {
      const files = event.target.files || event.dataTransfer.files;

      if (files.length) {
        const file = files[0];
        const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
        const maxSizeInBytes = 50 * 1024; // Taille maximale autorisée en octets (50 Ko)

        if (!allowedTypes.includes(file.type)) {
          Vue.toasted.error("Veuillez sélectionner une image au format JPG, JPEG ou PNG.");
          event.target.value = "";
          return;
        }

        if (file.size > maxSizeInBytes) {
          Vue.toasted.error("L'image est trop grande. Veuillez utiliser une image de taille inférieure.");
          event.target.value = "";
          return;
        }

        const reader = new FileReader();

        reader.onload = (element) => {
          if (element.target.result) {
            forms.customerForm.customerIdentityForm.logo = element.target.result;
          }
        };

        reader.readAsDataURL(file);
      }
    },
    changeCSS() {
      document
        .getElementById("client-picture-group")
        .classList.remove("picture");
      document.getElementById("file-select-button").classList.remove("browse");
      document.getElementById("file-select-button").classList.add("browse2");
    },
  },
};
</script>