<template>
  <div class="header_retix" id="header_retix">
    <div class="container-retix">
      <div class="row">
        <div class="col-6">
          <router-link to="/" class="logo_retix">
            <img src="../assets/images/logo_retix.svg" alt="Logo Retix" border="0" />
          </router-link>
          <span class="version_class" v-if="showVersion()">{{ getVersion() }}</span>
        </div>
        <div class="col-6 align_right">
          <ul class="nav_retix">
            <li v-if="!isLoginPage && !isAuthenticated">
              <router-link to="/login" class="cta_secondary">
                <span class="icon">
                  <img src="../assets/images/icon_login.svg" alt="Icône de connexion" border="0" />
                </span>
                <span class="txt">Connexion</span>
              </router-link>
            </li>
            <li v-else-if="!isLoginPage" @click="showLogoutModal">
              <a class="cta_secondary" @click="goToWithoutCheck('/home')">
                <span class="icon">
                  <img src="../assets/images/icon_logout.svg" alt="Icône de déconnexion" border="0" />
                </span>
                <span class="txt">Déconnexion</span>
              </a>
            </li>
            <li v-if="!isLoginPage">
              <router-link to="/" class="cta_secondary">
                <span class="icon">
                  <img src="../assets/images/icon_home.svg" alt="Icône d'accueil" border="0" />
                </span>
                <span class="txt">Accueil</span>
              </router-link>
            </li>
            <li v-if="isAdmin">
              <router-link :to="{name: 'information', params: {source: 'menu', type: 'creation'}}" class="cta_secondary">
                <span class="icon">
                  <img src="../assets/images/icon_customer.svg" alt="Icône nouveau client" border="0" />
                </span>
                <span class="txt">Nouveau client</span>
              </router-link>
            </li>
            <li v-if="!isCurrentPageSimulation() && getSimulation && getSimulation.id && !isLoginPage">
              <router-link :to="{ name: 'simulation' }" class="cta_secondary">
                <span class="icon">
                  <img src="../assets/images/simulation.svg" alt="Icône de simulation" border="0" />
                </span>
                <span class="txt">Simulation</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <modal-logout-confirmation ref="logoutModal" title="Confirmation de déconnexion" message="Êtes-vous sûr de vouloir vous déconnecter ?"></modal-logout-confirmation>
  </div>
</template>

<script>
import ModalLogoutConfirmation from './ModalLogoutConfirmation.vue';
import { mapGetters } from "vuex";
import { version } from "../../package.json";

export default {
  name: "Menu",
  components: {
    ModalLogoutConfirmation
  },
  computed: {
    ...mapGetters({
      getSimulation: "customer/getSimulation"
    }),
    isAuthenticated() {
      return !!localStorage.getItem('jwtToken');
    },
    isAdmin() {
      const user = JSON.parse(localStorage.getItem('user'));
      return user && user.roles.includes('ROLE_admin');
    },
    isLoginPage() {
      return this.$route.name === "login";
    }
  },
  methods: {
    isCurrentPageSimulation() {
      return this.$route.name === "simulation";
    },
    getVersion() {
      return version;
    },
    showVersion() {
      return process.env.NODE_ENV;
    },
    showLogoutModal() {
      this.$refs.logoutModal.$bvModal.show('logout-modal');
    },
      goToWithoutCheck: function (route) {
          if (route === "/load_curve" && forms.customerForm.consumptionCurveForm) {
              this.$router.push(route);
          }
          if (route === "/scenario" && forms.customerForm.scenariosForm) {
              this.$router.push(route);
          }
          if (route === "/goal" && forms.customerForm.consumptionCurveForm.annualConsumption) {
              this.$router.push(route);
          }
          if (route === "/information") {
              this.$router.push(route);
          }
      }
  }
};
</script>
