export function updateSingleValues(self:any) {

    const autoProduction = {
        objectif: self.forms.headerSimulationForm.autoProductionRatio[0],
        valeur: self.forms.headerSimulationForm.autoProductionRatio[1],
    }
    self.$store.commit('single/setAutoProduction', autoProduction)
    const autoConsommation = {
        objectif: self.forms.headerSimulationForm.autoConsumptionRatio[0],
        valeur: self.forms.headerSimulationForm.autoConsumptionRatio[1],
    }
    self.$store.commit('single/setAutoConsommation ', autoConsommation )
    const economie = {
        objectif: self.forms.headerSimulationForm.savingRatio[0],
        valeur: self.forms.headerSimulationForm.savingRatio[1],
    }
    self.$store.commit('single/setEconomie', economie)
}