<template>
  <b-form @submit="onSubmit" @reset="onReset" v-if="show">
    <div class="overlay_retix">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="container-retix">
              <div class="row">
                <div class="col-12 align_right">
                  <a @click="closePage();goToWithoutCheck('/goal')" class="cta_close">
                    <img src="../assets/images/arrow_close.svg" border="0" alt="CTA Close" />
                  </a>
                </div>
              </div>
              <!-- -->
              <div class="row">
                <div class="col-12">
                  <span class="typo_small_overlay">1/2</span>
                  <span
                    class="typo_big_overlay"
                  >Le calcul peut prendre plusieurs minutes, êtes-vous certain de vouloir lancer le processus ?</span>
                </div>
              </div>
              <!-- -->
              <div class="row">
                <div class="col-12">
                  <a @click="nextPage();goToWithoutCheck('/goal')" class="load_more">
                    <div class="load_more_mask">
                      <span class="txt">CONTINUER</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
export default {
  data() {
    return {
      show: true
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
    },
    closePage() {
      this.$parent.page = 0;
    },
    async nextPage() {
      this.$parent.page = 2;
      const objectToUpdate = {
        customerId: forms.id,
        minimumAutoProductionRate: forms.customerForm.simulationGoalsForm.minimumAutoProductionRate,
        maximumWithdrawPower: forms.customerForm.simulationGoalsForm.maximumWithdrawPower,
        maximumAnnualNetworkBilling: forms.customerForm.simulationGoalsForm.maximumAnnualNetworkBilling
      }
      await this.$store.dispatch('customer/getScenarios', objectToUpdate)
      const customerResponse = this.$store.getters['customer/getScenarios']

      forms.customerForm.scenariosForm = await customerResponse.scenariosView
      this.$router.push({name: 'scenario'})
    },
    onReset(evt) {
      evt.preventDefault();
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
      goToWithoutCheck: function (route) {
          if (route === "/load_curve" && forms.customerForm.consumptionCurveForm) {
              this.$router.push(route);
          }
          if (route === "/scenario" && forms.customerForm.scenariosForm) {
              this.$router.push(route);
          }
          if (route === "/goal" && forms.customerForm.consumptionCurveForm.annualConsumption) {
              this.$router.push(route);
          }
          if (route === "/information") {
              this.$router.push(route);
          }
      }
  }
};
</script>