<template>
  <div
    class="report-page"
    v-if="
      getSimulation &&
        getSimulation.simulation &&
        getSimulation.simulation.annualSimulation
    "
  >
    <div class="header-top">
      <span @click="goToSimulation">
        <img class="back-icon" src="@/assets/images/arrows.svg" />
      </span>
      <img
        src="../assets/images/retix-logo.svg"
        class="retix-logo"
        alt="retix logo"
      />
      <span>Rapport d'études préliminaire - {{ forms.customerForm.customerIdentityForm.companyName }}</span>
      <span style="padding-left: 4%;">Puissance = {{forms.simulationParametersForm.installedPower}} kWc</span>
    </div>
    <div class="header-bottom">
      <img
        :src="forms.customerForm.customerIdentityForm.logo"
        class="mantu-logo"
        alt="mantu logo"
      />
      <span>
        Etude de l'impact sur les consommations et le soutirage de
        l'installation d'une centrale de production photovoltaïque couplée à une
        capacité de stockage.
      </span>
    </div>
    <div class="container">
      <div class="text-block">
        <div class="row">
          <div class="col-4">
            <h3 class="heading">Données</h3>
            <p>Puissances soutirées du site en points 10'</p>
            <span></span>
            <p>Données réelles année {{new Date().getFullYear()-1}}</p>
          </div>
          <div class="col-4">
            <h3 class="heading">Scénarios</h3>
            <p>Puissance Photovoltaïque : {{forms.simulationParametersForm.installedPower}} kWc</p>
            <span></span>

           <p>Stockage : {{forms.simulationParametersForm.batteryCapacity}}  kWh</p>
          </div>
          <div class="col-4">
            <h3 class="heading">Paramètres étudiés</h3>
            <p>Taux d'auto-production</p>
            <p>Taux d'auto-consommation</p>
            <p>Réduction de puissance souscrite</p>
            <p>Compte de résultat de l'investissement</p>
            <p>Impact facturation</p>
          </div>
        </div>
      </div>
      <div class="radial">
        <div class="row diagram-text">
          <div class="col-4">
            <h3>Auto-production</h3>
            <div class="flex">
              <div
                class="text-col"
                v-if="
                  getSimulation.simulation.annualSimulation.autoProductionGoal
                "
              >
                <div>
                  <p>Objectifs / production</p>
                  <span></span>
                  <p>
                    {{
                      getSimulation.simulation.annualSimulation.autoProductionGoal
                    }}
                    kWh
                  </p>
                </div>
                <radial-progress-bar
                  style="margin: auto"
                  :diameter="120"
                  :strokeWidth="3"
                  startColor="#009fe3"
                  stopColor="#009fe3"
                  innerStrokeColor="#D4D4D4"
                  :completed-steps="
                    getSimulation.simulation.annualSimulation
                      .autoProductionRateGoal
                  "
                  :total-steps="100"
                >
                  <div>
                    <h1>
                      {{
                        getSimulation.simulation.annualSimulation
                            .autoProductionRateGoal
                      }}
                    </h1>
                    %
                  </div>
                </radial-progress-bar>
              </div>
              <div
                class="text-col"
                v-if="
                  getSimulation.simulation.annualSimulation
                    .simulationAutoProduction
                "
              >
                <div>
                  <p>Valeur de la simulation</p>
                  <span></span>
                  <p>
                    {{
                      getSimulation.simulation.annualSimulation.simulationAutoProduction
                    }}
                    kWh
                  </p>
                </div>
                <radial-progress-bar
                  style="margin: auto"
                  :diameter="120"
                  :strokeWidth="3"
                  startColor="#f4663a"
                  stopColor="#f4663a"
                  innerStrokeColor="#D4D4D4"
                  :completed-steps="
                    getSimulation.simulation.annualSimulation
                      .simulationAutoProductionRate
                  "
                  :total-steps="100"
                >
                  <div>
                    <h1>
                      {{
                        getSimulation.simulation.annualSimulation
                            .simulationAutoProductionRate
                      }}
                    </h1>
                    %
                  </div>
                </radial-progress-bar>
              </div>
            </div>
          </div>

          <div class="col-4">
            <h3>Auto-consommation</h3>
            <div class="flex">
              <div
                class="text-col"
                v-if="getSimulation.simulation.annualSimulation.autoConsumption"
              >
                <div>
                  <p>Valeur de la simulation</p>
                  <span></span>
                  <p>
                    {{
                      getSimulation.simulation.annualSimulation.autoConsumption
                    }}
                    kWh
                  </p>
                </div>
                <radial-progress-bar
                  style="margin: auto"
                  :diameter="120"
                  :strokeWidth="3"
                  startColor="#b5d131"
                  stopColor="#b5d131"
                  innerStrokeColor="#D4D4D4"
                  :completed-steps="
                    getSimulation.simulation.annualSimulation
                      .simulationAutoConsumptionRate
                  "
                  :total-steps="100"
                >
                  <div>
                    <h1>
                      {{
                        getSimulation.simulation.annualSimulation
                            .simulationAutoConsumptionRate
                      }}
                    </h1>
                    %
                  </div>
                </radial-progress-bar>
              </div>
            </div>
          </div>

          <div class="col-4" style="display: block">
            <h3>Economie</h3>
            <div class="flex">
              <div class="text-col" v-if="forms && forms.customerForm.simulationGoalsForm && forms.customerForm.simulationGoalsForm.maximumAnnualNetworkBilling">
                <div>
                  <p>Objectifs</p>
                  <span></span>
                  <p>{{forms.customerForm.simulationGoalsForm.maximumAnnualNetworkBilling }}€</p>
                </div>
              </div>
              <div class="text-col" v-if="getSimulation.simulation.annualSimulation.saving">
                <div>
                  <p>Valorisation auto-consommation</p>
                  <span></span>
                  <p>{{getSimulation.simulation.annualSimulation.saving }} €</p>
                </div>
                  <div>
                      <p>Valorisation surplus</p>
                      <span></span>
                      <p>{{getSimulation.simulation.annualSimulation.injectedEnergyValuation }} €</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="info-row">
        <div class="row">
          <div class="col-6">
            <div class="info-block">
              <h3>Surplus</h3>
              {{
                getSimulation.simulation.annualSimulation.productionSurplus
              }}
              <span>kWh</span>
            </div>
          </div>
          <div class="col-6">
            <div class="info-block">
              <h3>Economie globale</h3>
              {{
                getSimulation.simulation.annualSimulation.resalePerYear
              }}
              <span>€</span>
            </div>
          </div>
        </div>
      </div>
      <div class="graph-block">
        <h3 class="heading"  v-if="
                forms.customerForm.simulationForm &&
                forms.customerForm.simulationForm.annualSimulation &&
                forms.customerForm.simulationForm.annualSimulation.pictureYear ||
                forms.customerForm.simulationForm &&
                forms.customerForm.simulationForm.annualSimulation &&
                forms.customerForm.simulationForm.annualSimulation.pictureMonth">Évolution de l’achat d’énergie électrique</h3>
        <div class="row">
          <div
            class="col-4"
            v-if="
              forms.customerForm.simulationForm &&
                forms.customerForm.simulationForm.annualSimulation &&
                forms.customerForm.simulationForm.annualSimulation.pictureYear
            "
          >
            <img
              class="yearly-graph-img"
              :src="forms.customerForm.simulationForm.annualSimulation.pictureYear"
            />
          </div>
          <div
            class="col-8"
            v-if="
              forms.customerForm.simulationForm &&
                forms.customerForm.simulationForm.annualSimulation &&
                forms.customerForm.simulationForm.annualSimulation.pictureMonth
            "
          >
            <img
              class="monthly-graph-img"
              :src="forms.customerForm.simulationForm.annualSimulation.pictureMonth"
            />
          </div>
        </div>
        <div class="row" v-if="
                forms.customerForm.simulationForm &&
                forms.customerForm.simulationForm.annualSimulation &&
                forms.customerForm.simulationForm.annualSimulation.pictureYear ||
                forms.customerForm.simulationForm &&
                forms.customerForm.simulationForm.annualSimulation &&
                forms.customerForm.simulationForm.annualSimulation.pictureMonth">
          <div class="legend-block">
            <div class="legend-item">
              <div class="color"></div>
              Situation actuelle
            </div>
            <div class="legend-item">
              <div class="color"></div>
              Projet
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="info">
              <h3>Consommation annuelle</h3>
              {{
                getSimulation.simulation.annualSimulation.consumption
              }}
              <span>kWh</span>
            </div>
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-6">
                <div class="info">
                  <h3>Réduction de puissance souscrite</h3>
                  {{
                    getSimulation.simulation.annualSimulation.subscribedPowerReduction
                  }}
                  <span>kVA</span>
                </div>
              </div>
              <div class="col-6">
                <div class="info">
                  <h3>Réduction du soutirage réseau</h3>
                  {{
                    getSimulation.simulation.annualSimulation.networkWithdrawReduction
                  }}
                  <span>kWh</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p style="page-break-after: always;"></p>
    <div class="container">
      <div class="info-cards">
        <div class="card">
          <h3>Puissance moyenne soutirée</h3>
          {{
            getSimulation.simulation.annualSimulation.newAverageWithdrawnPower
          }}
          kVA
          <span
            >(
            {{
              getSimulation.simulation.annualSimulation.currentAverageWithdrawnPower
            }}
            KVA )</span
          >
        </div>
        <div class="card">
          <h3>Nombre de cycles de la batterie</h3>
          {{ getSimulation.simulation.annualSimulation.batteryCyclesNumber }}
        </div>
        <div class="card">
          <h3>Ratio HC/HP</h3>
          {{
            getSimulation.simulation.annualSimulation.newFullHoursToOffPeakHoursRate
          }}
          %
          <span
            >(
            {{
              getSimulation.simulation.annualSimulation.currentFullHoursToOffPeakHoursRate
            }}
            % )</span
          >
        </div>
        <div class="card">
          <h3>Energie perdue par limitation de puissance</h3>
          {{
            getSimulation.simulation.annualSimulation.powerLimitedLostEnergy
          }}
          kWh
        </div>
        <div class="card">
          <h3>Compensation carbone de l’opération</h3>
          {{
            getSimulation.simulation.annualSimulation.carbonOffsetting
          }}
          kgCO2
        </div>
        <div class="card">
          <h3>Réduction de facture annuelle</h3>
          {{
           getBillingReduction()
          }}
          €
        </div>
      </div>
      <div class="graph-block">
        <h3 class="heading">Journée type en semaine (en kWh)</h3>
        <img class="weekly_graph_class"
          :src="forms.customerForm.simulationForm.annualSimulation.pictureMonthlySimulationDay"
        />
      </div>
      <div class="graph-block">
        <h3 class="heading">Journée type en week-end (en kWh)</h3>
        <img class="weekly_graph_class"
          :src="forms.customerForm.simulationForm.annualSimulation.pictureMonthlySimulationEnd"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import RadialProgressBar from "vue-radial-progress";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Single",
  components: {
    RadialProgressBar,
  },
  computed: {
    ...mapGetters({
      getSimulation: "customer/getSimulation",
      autoProduction: "single/getAutoProduction",
      autoConsommation: "single/getAutoConsommation",
      economie: "single/getEconomie",
    }),
  },
  mounted() {
    if (
      !this.getSimulation ||
      !this.getSimulation.simulation ||
      !this.getSimulation.simulation.annualSimulation
    ) {
      this.$router.push("/");
    }
  },
  methods: {
    goToSimulation() {
      this.$router.push("/simulation");
    },
    getBillingReduction() {
      return (
        forms.customerForm.simulationForm.annualSimulation.saving
      );
    },
  },
});
</script>

<style scoped>
.report-page {
  max-width: 297mm;
  margin: 0 auto;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  color: #222222;
}

@media print {
  .report-page {
    max-width: 100%;
    width: 100%;
  }

  .container {
    max-width: 100%;
    width: 100%;
  }
}

.header-top {
  display: flex;
  align-items: center;
  background: #009fe3;
  color: #fff;
  padding: 15px 22px;
  font-size: 12px;
  position: fixed;
  top: 0;
  z-index: 2;
  width: 297mm;
}

.header-bottom {
  margin-top: 47px;
  display: flex;
  align-items: center;
  padding: 15px 17px;
  background: #fff;
  color: #009fe3;
  font-size: 12px;
  line-height: 15px;
  border-bottom: 1px solid #009fe3;
  z-index: 3;
}


.retix-logo {
  width: 70px;
  height: auto;
  margin-right: 30px;
}

.mantu-logo {
  width: 80px;
  height: auto;
  margin-right: 24px;
}

.container {
  padding: 0 22px;
  background: #fff;
  z-index: 2;
}

h2,
h3 {
  margin: 0;
  color: #222222;
  font-weight: 500;
}

.heading {
  font-size: 18px;
  font-family: "Raleway", sans-serif;
}

.text-block {
  background: #f6f6f6;
  text-align: center;
  padding: 25px 0;
  margin-bottom: 40px;
}

.text-block .heading {
  margin-bottom: 13px;
}

.text-block p {
  font-size: 13px;
  line-height: 17px;
  margin: 0;
}

.text-block span {
  display: block;
  margin: 10px auto 11px;
  width: 38px;
  height: 1px;
  background: #000;
}

.text-block .col-4 {
  padding: 0 15px;
}

.info-block {
  background: #009fe3;
  color: #fff;
  border-radius: 20px;
  font-size: 36px;
  text-align: center;
  padding: 23px 0;
}

.info-row .col-6:nth-child(1) .info-block {
  margin-right: 15px;
}

.info-row .col-6:nth-child(2) .info-block {
  margin-left: 15px;
}

.info-block h3 {
  font-size: 18px;
  color: #fff;
  margin-bottom: 7px;
}

.info-block span {
  font-size: 15px;
}

.info-row {
  margin-bottom: 45px;
  margin-top: 10px;
}

.graph-block {
  text-align: center;
}

.graph-block .heading {
  margin-bottom: 30px;
}

.graph-block img {
  margin-bottom: 23px;
  width: 100%;
  max-height: 380px;
  height: 380px;
}

.graph-block .col-4 {
  padding-right: 25px;
}

.graph-block .info {
  font-size: 36px;
  text-align: center;
  padding: 0 25px;
  margin-bottom: 32px;
  font-weight: 500;
}

.graph-block .info h3 {
  font-size: 16px;
  margin-bottom: 7px;
  color: #222222;
}

.graph-block .info span {
  font-size: 15px;
}

.info-cards {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 40px;
}

.info-cards .card {
  width: 140px;
  border-radius: 10px;
  border: 0;
  background: #f6f6f6;
  font-size: 14px;
  text-align: center;
  padding: 20px 10px;
}

@media print {
  .info-cards {
    padding-top: 80px;
  }
}

.info-cards .card span {
  font-style: italic;
}

.card h3 {
  font-size: 14px;
  margin-bottom: 10px;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.diagram-text .flex {
  display: flex;
  justify-content: center;
}

.diagram-text h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.diagram-text p {
  font-size: 13px;
  line-height: 17px;
  margin: 0;
  text-align: center;
}

.diagram-text span {
  display: block;
  margin: 10px auto 11px;
  width: 20px;
  height: 1px;
  background: #009fe3;
}

.diagram-text .col-4 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.diagram-text .col-4:nth-child(1) {
  padding-right: 10px;
}

.diagram-text .col-4:nth-child(2) {
  padding: 0 10px;
}

.diagram-text .col-4:nth-child(3) {
  padding-left: 10px;
}

.text-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 152px;
  width: 50%;
}

.diagram-text .radial-progress-container {
  margin: 14px 0 24px !important;
}

.diagram-text .radial-progress-inner h1 {
  font-size: 36px;
}

.monthly-graph-img,
.yearly-graph-img {
  height: auto !important;
}

.weekly_graph_class {
  height: 500px !important;
  max-height: 500px !important;
}
</style>
