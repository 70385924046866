<template>
  <b-modal id="error-modal" :title="title">
    <p>{{ message }}</p>

    <template v-slot:modal-footer="{ok}">
      <b-button size="sm" @click="ok()">OK</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: ['message','title']
}
</script>