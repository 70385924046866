const _ = require('lodash');
export default {
	isInformationValid,
	isPercentageValid,
	isDayMonthDateValid,
	isGoalValid,
	isPartialLoadCurveValid,
	isLoadCurveAnalyseValid,
};

function isInformationValid(form: any) {
	let isValid = false;
	if (
		form.customerIdentityForm.companyName &&
		form.customerIdentityForm.logo &&
		form.customerIdentityForm.address &&
		form.customerIdentityForm.city &&
		form.customerIdentityForm.postalCode &&
		form.customerIdentityForm.country &&
		form.electricalIdentityForm.subscription != null &&
		form.electricalIdentityForm.subscribedPower != null &&
		form.electricalIdentityForm.energyTaxes != null &&
		form.projectParametersForm.orientationReferential.id != null &&
		form.projectParametersForm.minimumPowerPeak != null &&
		form.projectParametersForm.maximumPowerPeak != null &&
		form.projectParametersForm.maximumStorageCapacity != null &&
		form.projectParametersForm.batteryPerformanceRate != null &&
		form.projectParametersForm.maximumChargeDischargePower != null &&
		form.projectParametersForm.maximumInjectionPower != null &&
		form.projectParametersForm.producible != null &&
		form.projectParametersForm.surplusValuationTariff != null &&
		form.projectParametersForm.averageCarbonContentPerKWh != null &&
		form.projectIdentityForm.projectStatus != null &&
		form.projectIdentityForm.projectManager != null &&
		form.userIdentificationForm.email != null &&
		form.userIdentificationForm.password != null &&
		form.projectParametersForm.weekendStartDay != null &&
		form.projectParametersForm.description &&
		((form.electricalIdentityForm.uniqueTariff && form.electricalIdentityForm.purchaseTariff) ||
			(!form.electricalIdentityForm.uniqueTariff &&
				form.electricalIdentityForm.winterFullHoursTariff &&
				form.electricalIdentityForm.winterOffHoursTariff &&
				form.electricalIdentityForm.summerFullHoursTariff &&
				form.electricalIdentityForm.summerOffHoursTariff &&
				form.electricalIdentityForm.seasonReferentialFormatted.summerStartDate &&
				form.electricalIdentityForm.seasonReferentialFormatted.winterStartDate))
	) {
		isValid = true;
	}
	//hello
	let index = 0;
	if(!form.electricalIdentityForm.uniqueTariff){
		form.electricalIdentityForm.offPeakHours.forEach((element: any) => {
			isValid =
				isValid &&
				element.startOffPeakHours !== '' &&
				element.endOffPeakHours !== '';
			index++;
		});
	}

	isValid = isValid && isPercentageValid(form.projectParametersForm.batteryPerformanceRate);



	if (!form.electricalIdentityForm.uniqueTariff) {
		isValid = isDayMonthDateValid(form.electricalIdentityForm.seasonReferentialFormatted.summerStartDate);
		isValid = isDayMonthDateValid(form.electricalIdentityForm.seasonReferentialFormatted.winterStartDate);
	}
	return isValid;
}

function isPercentageValid(value: number) {
	return value >= 0 && value <= 100;
}

function isDayMonthDateValid(value: string) {
	const regex1 = '(0[1-9]|1[0-9]|2[0-9]|3[01])/(01|03|05|07|08|10|12)';
	const regex2 = '(0[1-9]|1[0-9]|2[0-9]|30)/(04|06|09|11)';
	const regex3 = '(0[1-9]|1[0-9]|2[0-9])/02';
	const fullRegex = `^${regex1}|${regex2}|${regex3}$`;
	return new RegExp(fullRegex).test(value);
}

function isGoalValid(form: any) {
	let isValid = false;
	if ((form.minimumAutoProductionRate >= 0) && (form.maximumWithdrawPower >= 0) && (form.maximumAnnualNetworkBilling >= 0)) {
		isValid = true;
	}
	isValid = isValid && isPercentageValid(form.minimumAutoProductionRate);
	return isValid;
}

function isPartialLoadCurveValid(form: any) {
	return !form.months.some((month: any) => month.value == '');
}

function isLoadCurveAnalyseValid(form: any) {
	let isValid = false;

	if (
		form.annualConsumption &&
		form.fullHoursToOffPeakHoursRate &&
		form.averageWithdrawPower &&
		form.maximumWithdrawPower &&
		form.monthlyConsumptions &&
		form.monthlyConsumptions.length == 12 &&
		form.monthlyConsumptions.every((element: any) => element && element > 0)
	) {
		isValid = true;
	}

	return isValid;
}

function isImageValid(form: any) {
	let isValid = false;
	const maxSizeInBytes = 50 * 1024;

	if (form.logo.size < maxSizeInBytes) {
		isValid = true;
	}

	return isValid;
}
