<template>
<li class="col-4">
  <div class="bloc_content">
    <h2>Objectifs</h2>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group
        id="goal-input-group-auto-production-rate"
        class="bloc_form"
      >
        <label>Taux d'auto production supérieure à <i>(%)</i></label>
        <b-form-input
          id="client-input-auto-production-rate"
          v-model="forms.customerForm.simulationGoalsForm.minimumAutoProductionRate"
          type="number"
          step="any"
          class="input_form"
          required
        />
      </b-form-group>

      <b-form-group
        id="goal-input-group-maximum-power"
        class="bloc_form"
      >
        <label>Puissance soutirée maximale <i>(kVA)</i></label>
        <b-form-input
          id="client-input-maximum-power"
          v-model="forms.customerForm.simulationGoalsForm.maximumWithdrawPower"
          type="number"
          step="any"
          class="input_form"
          required
        />
      </b-form-group>

      <b-form-group
        id="goal-input-group-annual-invoicing"
        class="bloc_form"
      >
        <label>Facturation annuelle nette réseau maximale de <i>(&euro;)</i></label>
        <b-form-input
          id="client-input-annual-invoicing"
          v-model="forms.customerForm.simulationGoalsForm.maximumAnnualNetworkBilling"
          type="number"
          step="any"
          class="input_form"
          required
        />
      </b-form-group>
    </b-form>
  </div>
</li>
</template>

<script>
import validation from "../utils/validation";

export default {
  data() {
    return {
      show: true
    };
  },
  mounted() {
    this.validateGoalForm();
  },
  watch: {
    forms: {
      handler(newVal, oldVal) {
        this.validateGoalForm();
      },
      deep: true
    }
  },
  methods: {
    validateGoalForm() {
      if ((this.forms.customerForm.simulationGoalsForm.minimumAutoProductionRate == 0) && (this.forms.customerForm.simulationGoalsForm.maximumWithdrawPower == 0) && (this.forms.customerForm.simulationGoalsForm.maximumAnnualNetworkBilling == 0)){
        document
            .getElementById("reactive_cta_next")
            .classList.add("cta_next_valid");
      }else if (validation.isGoalValid(this.forms.customerForm.simulationGoalsForm)) {
        document
          .getElementById("reactive_cta_next")
          .classList.add("cta_next_valid");
      } else {
        document
          .getElementById("reactive_cta_next")
          .classList.remove("cta_next_valid");
      }
    },
    percentageState(value) {
      return validation.isPercentageValid(value) ? null : false;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onReset(evt) {
      evt.preventDefault();
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  }
};
</script>