<template>
<div>
  <GoalsSubPage01 v-if="page==1" />
  <GoalsSubPage02 v-if="page==2" />

  <div class="row">
    <div class="col-12">
      <div class="cta_prev_next">
        <a class="cta_prev" @click="goToWithoutCheck('/load_curve')">
          <span class="icon">
            <img src="../assets/images/arrow_cta_prev.svg" border="0" alt="Arrow prev">
          </span>
        </a>
        <a class="cta_next" id="reactive_cta_next" @click="page=1">
          <span class="icon">
            <img src="../assets/images/arrow_cta_next.svg" border="0" alt="Arrow prev">
          </span>
        </a>
      </div>

      <div class="content_retix">
        <div class="container-retix">
          <!-- -->
          <div class="row">
            <div class="col-12">
              <h1 v-if="!forms.customerForm.customerIdentityForm.companyName">Nouveau client</h1>
              <h1 v-if="forms.customerForm.customerIdentityForm.companyName">{{ forms.customerForm.customerIdentityForm.companyName }}</h1><span class="step_customer">( Étape 3/4 )</span>
            </div>
          </div>
          <!-- -->
          <div class="row">
            <div class="col-12">
              <div class="timeline">    
                <div class="row">
                  <div id="wizzard_informations" class="col done" @click="goTo('/information')">
                    <div class="dot_timeline"><span class="minidot_timeline"></span></div>
                    <span class="txt_timeline">
                      Informations
                    </span>
                  </div>
                  <div id="wizzard_load" class="col done" @click="goTo('/load_curve')">
                    <div class="dot_timeline"><span class="minidot_timeline"></span></div>
                    <span class="txt_timeline">
                      Courbe de charge
                    </span>
                  </div>
                  <div id="wizzard_goals" class="col actif" @click="goTo('/goal')">
                    <div class="dot_timeline"><span class="minidot_timeline"></span></div>
                    <span class="txt_timeline">
                      Objectifs
                    </span>
                  
                  </div>
                  <div id="wizzard_scenarii" class="col" @click="goTo('/scenario')">
                    <div class="dot_timeline"><span class="minidot_timeline"></span></div>
                    <span class="txt_timeline">
                      Scenarii
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- -->

          <div class="content_customer">
            <ul class="row">
              <consumptionCurveForm />
              <simulationGoalsForm />
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import consumptionCurveForm from "../components/LoadCurveAnalyse_form";
import simulationGoalsForm from "../components/simulationGoals_form";
import GoalsSubPage01 from "../components/Goals_SubPage1";
import GoalsSubPage02 from "../components/Goals_SubPage2";
import customerUtil from "../utils/customer"
import validation from "../utils/validation";

export default {
  name: "App",
  components: {
    consumptionCurveForm,
    simulationGoalsForm,
    GoalsSubPage01,
    GoalsSubPage02
  },
  data () {
    return {
      page: 0
    }
  }, 
  async mounted() {
    await this.selectCustomer()
    this.updateWizard()
  },
  methods:{
    updateWizard: function() {
      document.getElementById('wizzard_informations').classList.remove('actif')
      document.getElementById('wizzard_goals').classList.add('actif')
      document.getElementById('wizzard_load').classList.remove('actif')
      document.getElementById('wizzard_scenarii').classList.remove('actif')
    },
    goTo: function(route) {
      if (route === "/load_curve" && validation.isLoadCurveAnalyseValid(this.forms.customerForm.consumptionCurveForm)) {
        this.$router.push(route);
      }
      if (route === "/scenario" && forms.customerForm.scenariosForm) {
        this.$router.push(route);
      }
      if (route === "/goal" && forms.customerForm.consumptionCurveForm) {
        this.$router.push(route);
      }
      if (route === "/information") {
        this.$router.push(route);
      }
    },
    async selectCustomer() {
      let id = window.localStorage.getItem('customerId') ? JSON.parse(window.localStorage.getItem('customerId')) : -1
      if (forms.id >= 0 || id >= 0) {
        if (forms.id >= 0) {
          id = forms.id
        }
        await this.$store.dispatch('customer/get', {id})
        const response = this.$store.getters['customer/get']
      
        if (response.error) {
          this.errorTitle = "Get error"
          this.errorMessage = util.getErrorMessage(response.error)
          this.$bvModal.show('error-modal')
        } else {
          customerUtil.updateCustomerForm(response, forms, id)
        }
      }
    },
      goToWithoutCheck: function (route) {
          if (route === "/load_curve" && forms.customerForm.consumptionCurveForm) {
              this.$router.push(route);
          }
          if (route === "/scenario" && forms.customerForm.scenariosForm) {
              this.$router.push(route);
          }
          if (route === "/goal" && forms.customerForm.consumptionCurveForm.annualConsumption) {
              this.$router.push(route);
          }
          if (route === "/information") {
              this.$router.push(route);
          }
      }
  }
};
</script>
