<template>
    <b-form @reset="onReset" @submit="onSubmit">
        <b-form-group class="content_customer">
            <b-form-row>
                <b-col>
                    <div class="bloc_content" style="margin-right: 10px">

                        <h2>Auto-production</h2>
                        <b-form-row class="row align_center margin_top_10 margin_bottom_10">
                            <b-col class="col-6 align_center">
                                <span class="sous_titre">Objectifs / production</span>
                                <b-form-group
                                        id="yearly-simularion-production-target"
                                        class="valeurs"
                                >{{ forms.customerForm.simulationForm.annualSimulation.autoProductionGoal }} kWh
                                </b-form-group>

                                <radial-progress-bar
                                        :completed-steps="
                    forms.customerForm.simulationForm.annualSimulation.autoProductionRateGoal
                  "
                                        :diameter="80"
                                        :strokeWidth="5"
                                        :total-steps="100"
                                        innerStrokeColor="#D4D4D4"
                                        startColor="#009FE3"
                                        stopColor="#009FE3"
                                        style="margin: auto"
                                >
                                    <div>
                                        <h1>{{
                                                forms.customerForm.simulationForm.annualSimulation.autoProductionRateGoal
                                            }}</h1>%
                                    </div>
                                </radial-progress-bar>
                            </b-col>
                            <b-col class="col-6 align_center">
                                <span class="sous_titre">Valeur de la simulation</span>
                                <b-form-group
                                        id="yearly-simulation-production-simulated"
                                        class="valeurs"
                                >{{ forms.customerForm.simulationForm.annualSimulation.simulationAutoProduction }} kWh
                                </b-form-group>

                                <radial-progress-bar
                                        :completed-steps="
                    forms.customerForm.simulationForm.annualSimulation.simulationAutoProductionRate
                  "
                                        :diameter="80"
                                        :strokeWidth="5"
                                        :total-steps="100"
                                        innerStrokeColor="#D4D4D4"
                                        startColor="#ED956A"
                                        stopColor="#ED956A"
                                        style="margin: auto"
                                >
                                    <div>
                                        <h1>{{
                                                forms.customerForm.simulationForm.annualSimulation.simulationAutoProductionRate
                                            }}</h1>%
                                    </div>
                                </radial-progress-bar>
                            </b-col>
                        </b-form-row>
                    </div>
                </b-col>

                <b-col class="col">
                    <div
                            class="bloc_content"
                            style="margin-left: 10px; margin-right: 10px"
                    >
                        <h2>Auto-consommation</h2>
                        <b-form-row class="row align_center margin_top_10 margin_bottom_10">
                            <b-col class="col-12 align_center">
                <span class="sous_titre" style="padding-bottom:22px"
                >Valeur de la simulation</span
                >
                                <b-form-group
                                        id="yearly-simulation-consumption-simulated"
                                        class="valeurs"
                                >{{ forms.customerForm.simulationForm.annualSimulation.autoConsumption }} kWh
                                </b-form-group>

                                <radial-progress-bar
                                        :completed-steps="
                    forms.customerForm.simulationForm.annualSimulation
                      .simulationAutoConsumptionRate
                  "
                                        :diameter="80"
                                        :strokeWidth="5"
                                        :total-steps="100"
                                        innerStrokeColor="#D4D4D4"
                                        startColor="#B5D131"
                                        stopColor="#B5D131"
                                        style="margin: auto"
                                >
                                    <div>
                                        <h1>{{
                                                forms.customerForm.simulationForm.annualSimulation.simulationAutoConsumptionRate
                                            }}</h1>%
                                    </div>
                                </radial-progress-bar>
                            </b-col>
                        </b-form-row>
                    </div>
                </b-col>

                <b-col class="col">
                    <div class="bloc_content" style="margin-left: 10px">
                        <h2>Economie</h2>
                        <b-form-row class="row align_center margin_top_10 margin_bottom_10">
                            <b-col class="col-6 align_center">
                <span class="sous_titre" style="padding-bottom:22px"
                >Objectifs annuels</span
                >
                                <b-form-group
                                        id="yearly-simularion-economy-target"
                                        class="valeurs"
                                >{{ forms.customerForm.simulationGoalsForm.maximumAnnualNetworkBilling }} &euro;
                                </b-form-group>

                            </b-col>
                            <b-col class="col-6 align_center">
                                <span class="sous_titre">Valorisation auto consommation</span>
                                <b-form-group
                                        id="yearly-simulation-economy-simulated"
                                        class="valeurs"
                                >{{
                                        forms.customerForm.simulationForm.annualSimulation.saving
                                    }}
                                    &euro;
                                </b-form-group
                                >

                                <span class="sous_titre">Valorisation surplus</span>
                                <b-form-group
                                        id="yearly-simulation-economy-simulated"
                                        class="valeurs"
                                >{{
                                        forms.customerForm.simulationForm.annualSimulation.injectedEnergyValuation
                                    }}
                                    &euro;
                                </b-form-group
                                >
                            </b-col>
                        </b-form-row>
                    </div>
                </b-col>
            </b-form-row>
        </b-form-group>

        <b-form-group class="content_customer">
            <b-form-row>
                <b-col>
                    <div class="bloc_content_02" style="margin-right: 8px">
                        <h2>Surplus</h2>
                        <div class="row">
                            <div class="col-12 align_center">
                <span
                        id="yearly-simulation-span-surplus"
                        class="valeurs_big"
                >{{ forms.customerForm.simulationForm.annualSimulation.productionSurplus }}</span>
                                <span class="valeurs_big_legende">kWh</span>
                            </div>
                        </div>
                    </div>
                </b-col>

                <b-col>
                    <div class="bloc_content_02" style="margin-left: 8px">
                        <h2>Economie globale</h2>
                        <div class="row">
                            <div class="col-12 align_center">
                                <span class="valeurs_big">{{
                                        forms.customerForm.simulationForm.annualSimulation.resalePerYear
                                    }}</span>
                                <span class="valeurs_big_legende">€</span>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-form-row>
        </b-form-group>

        <div class="content_customer hacked_width">
            <b-form-group class="row">
                <div class="col-12">
                    <h2>Évolution de l’achat d’énergie électrique</h2>
                </div>
                <b-form-row>
                    <b-col class="col-4">
                        <rounded-bar-chart
                                id="yearly-simulation-chart-yearly"
                                :chart-data="datacollection_yearly"
                                :height="400"
                                :isChanged="isChanged"
                                :options="options_yearly"
                                class="graph"
                        ></rounded-bar-chart>
                    </b-col>

                    <b-col class="col-8">
                        <rounded-bar-chart
                                id="yearly-simulation-chart-monthly"
                                :chart-data="datacollection_monthly"
                                :height="400"
                                :isChanged="isChanged"
                                :options="options_monthly"
                                class="graph"
                        ></rounded-bar-chart>
                    </b-col>
                </b-form-row>
            </b-form-group>
        </div>

        <div class="legend-block">
            <div class="legend-item">
                <div class="color"></div>
                Situation actuelle
            </div>
            <div class="legend-item">
                <div class="color"></div>
                Projet
            </div>

        </div>
        <b-form-group class="content_customer bottom_bloc">
            <b-form-row>
                <b-col style="margin-top: 10px">
                    <div class="bloc_content" style="margin-right: 10px">
                        <h2>Consommation annuelle</h2>
                        <div class="row">
                            <div class="col-12 align_center">
                <span
                        id="yearly-simulation-span-monthly-consumption"
                        class="valeurs_big"
                >{{ forms.customerForm.simulationForm.annualSimulation.consumption }}</span>
                                <span class="valeurs_big_legende">kWh</span>
                            </div>
                        </div>
                    </div>
                </b-col>

                <b-col style="margin-top: 10px">
                    <div class="bloc_content" style="margin-right: 10px">
                        <h2>Production prévisionnelle</h2>
                        <div class="row">
                            <div class="col-12 align_center">
                <span id="yearly-simulation-span-monthly-consumption"
                      class="valeurs_big"
                >{{ forms.customerForm.simulationForm.annualSimulation.production }}</span>
                                <span class="valeurs_big_legende">kWh</span>
                            </div>
                        </div>
                    </div>
                </b-col>

                <b-col style="margin-top: 10px">
                    <div class="bloc_content" style="margin-left: 10px; margin-right: 10px">
                        <h2>Réduction de puissance réseau</h2>
                        <div class="row">
                            <div class="col-12 align_center">
                                <span id="yearly-simulation-span-power-reduction" class="valeurs_big">{{
                                        forms.customerForm.simulationForm.annualSimulation.subscribedPowerReduction
                                    }}</span>
                                <span class="valeurs_big_legende">kVA</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 align_center">
                                <span class="valeurs_bottom">Nouveau soutirage maximum : {{
                                        forms.customerForm.simulationForm.annualSimulation.maximumPurchase
                                    }} kVA</span>
                            </div>
                        </div>
                    </div>
                </b-col>

                <b-col style="margin-top: 10px">
                    <div class="bloc_content" style="margin-left: 10px;">
                        <h2>Réduction du soutirage réseau</h2>
                        <div class="row">
                            <div class="col-12 align_center">
                <span
                        id="yearly-simulation-span-network-withdrawal-reduction"
                        class="valeurs_big"
                >{{ forms.customerForm.simulationForm.annualSimulation.networkWithdrawReduction }}</span>
                                <span class="valeurs_big_legende">kWh</span>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-form-row>
        </b-form-group>

        <b-form-group class="content_customer">
            <b-form-row>
                <b-col style="margin-top: 10px">
                    <div
                            id="yearly-simulation-div-average-power-withdrawal"
                            class="bloc_content_03"
                    >
                        <h4>Puissance moyenne soutirée</h4>
                        <span class="valeurs_bottom">{{
                                forms.customerForm.simulationForm.annualSimulation.newAverageWithdrawnPower
                            }} kVA</span>
                        <span class="valeurs_bottom">
              <i>( {{ forms.customerForm.simulationForm.annualSimulation.currentAverageWithdrawnPower }} KVA )</i>
            </span>
                    </div>
                </b-col>

                <b-col style="margin-top: 10px">
                    <div
                            id="yearly-simulation-div-number-battery-cycles"
                            class="bloc_content_03"
                    >
                        <h4>Nombre de cycles de la batterie</h4>
                        <span class="valeurs_bottom">{{
                                forms.customerForm.simulationForm.annualSimulation.batteryCyclesNumber
                            }}</span>
                    </div>
                </b-col>

                <b-col style="margin-top: 10px">
                    <div id="yearly-simulation-div-ratio-HC-HP" class="bloc_content_03">
                        <h4>Ratio HC/HP</h4>
                        <span class="valeurs_bottom">{{
                                forms.customerForm.simulationForm.annualSimulation.newFullHoursToOffPeakHoursRate
                            }} %</span>
                        <span class="valeurs_bottom"><i>( {{
                                forms.customerForm.simulationForm.annualSimulation.currentFullHoursToOffPeakHoursRate
                            }} % )</i></span>
                    </div>
                </b-col>

                <b-col style="margin-top: 10px">
                    <div id="yearly-simulation-div-energy-lost" class="bloc_content_03">
                        <h4>Energie perdue par limitation de puissance</h4>
                        <span class="valeurs_bottom">{{
                                forms.customerForm.simulationForm.annualSimulation.powerLimitedLostEnergy
                            }} kWh</span>
                    </div>
                </b-col>

                <b-col style="margin-top: 10px">
                    <div
                            id="yearly-simulation-div-carbon-compensation"
                            class="bloc_content_03"
                    >
                        <h4>Compensation carbone de l’opération</h4>
                        <span class="valeurs_bottom">{{
                                forms.customerForm.simulationForm.annualSimulation.carbonOffsetting
                            }} kgCO2</span>
                    </div>
                </b-col>

                <b-col style="margin-top: 10px">
                    <div id="yearly-simulation-div-yearly-invoice-redcution" class="bloc_content_03">
                        <h4>Réduction de facture annuelle</h4>
                        <span class="valeurs_bottom">{{ getBillingReduction() }} &euro;</span>
                        <span class="valeurs_bottom"><i>Ancienne facture annuelle : {{
                                forms.customerForm.simulationForm.annualSimulation.currentBilling
                            }} €</i></span>
                    </div>
                </b-col>
            </b-form-row>
        </b-form-group>
    </b-form>
</template>

<script>
import RoundedBarChart from "./charts/RoundedBarChart";
import RadialProgressBar from "vue-radial-progress";
import {updateSingleValues} from "@/utils/ratio";
import _ from "lodash";

export default {
    components: {
        RoundedBarChart,
        RadialProgressBar,
    },
    watch: {
        "forms.customerForm.simulationForm": {
            deep: true,
            handler(newParameter) {
                this.datacollection_yearly.datasets.forEach((dataset) => {
                    switch (dataset.label) {
                        case "Situation actuelle":
                            dataset.data = [forms.customerForm.simulationForm.annualSimulation.consumption];
                            break;
                        case "Projet":
                            dataset.data = [forms.customerForm.simulationForm.annualSimulation.purchase];
                            break;
                    }
                });

                this.datacollection_monthly.datasets.forEach((dataset) => {
                    switch (dataset.label) {
                        case "Projet":
                            dataset.data = forms.customerForm.simulationForm.annualSimulation.monthlyPurchases;
                            break;
                        case "Situation actuelle":
                            dataset.data =
                                forms.customerForm.simulationForm.annualSimulation.monthlyConsumptions;
                            break;
                    }
                });

                this.isChanged = !this.isChanged;
                this.$forceUpdate();
            },
        },
    },
    data() {
        return {
            isChanged: false,
            datacollection_yearly: {
                datasets: [
                    {
                        label: "Situation actuelle",
                        backgroundColor: "#D4D4D4",
                        data: [forms.customerForm.simulationForm.annualSimulation.consumption],
                    },
                    {
                        label: "Projet",
                        backgroundColor: "#B5D131",
                        data: [forms.customerForm.simulationForm.annualSimulation.purchase],
                    },
                ],
            },
            datacollection_monthly: {
                labels: [
                    "Janv",
                    "Fev",
                    "Mars",
                    "Avr",
                    "Mai",
                    "Juin",
                    "Juill",
                    "Août",
                    "Sept",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
                datasets: [

                    {
                        label: "Situation actuelle",
                        backgroundColor: "#D4D4D4",
                        data: forms.customerForm.simulationForm.annualSimulation.monthlyConsumptions,
                    },
                    {
                        label: "Projet",
                        backgroundColor: "#B5D131",
                        data: forms.customerForm.simulationForm.annualSimulation.monthlyPurchases,
                    },
                ],
            },
            options_yearly: {
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                            gridLines: {
                                display: true,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                },
                legend: {
                    display: false,
                },
                title: {
                    text: "Année complète",
                    position: "bottom",
                    display: true,
                },
                tooltips: {
                    enabled: true,
                    mode: "single",
                    callbacks: {
                        title: function (tooltipItems, data) {
                            return data.datasets[tooltipItems[0].datasetIndex].label;
                        },

                        label: function (tooltipItems, data) {
                            return tooltipItems.yLabel + " kWh";
                        },
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                height: 400,
                cornerRadius: 25,
                animation: {
                    onComplete: function () {
                        const url_base64jpYear = document.getElementById(
                            "yearly-simulation-chart-yearly"
                        );
                        if (url_base64jpYear.childNodes.length > 0) {
                            forms.customerForm.simulationForm.annualSimulation.pictureYear = null;
                            forms.customerForm.simulationForm.annualSimulation.pictureYear = _.find(
                                url_base64jpYear.childNodes,
                                (node) => node.localName === "canvas"
                            ).toDataURL("image/jpg");
                        }
                    },
                },
            },
            options_monthly: {
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                            gridLines: {
                                display: true,
                            },
                            stacked: false,
                        },
                    ],
                    xAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                            gridLines: {
                                display: false,
                            },
                            stacked: false,
                        },
                    ],
                },
                legend: {
                    display: false,
                },
                tooltips: {
                    enabled: true,
                    mode: "single",
                    callbacks: {
                        title: function (tooltipItems, data) {
                            return `${tooltipItems[0].label} - ${data.datasets[tooltipItems[0].datasetIndex].label}`;
                        },

                        label: function (tooltipItems, data) {
                            return tooltipItems.yLabel + " kWh";
                        },
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                cornerRadius: 50,
                height: 400,
                animation: {
                    onComplete: function () {
                        const url_base64jpMonth = document.getElementById(
                            "yearly-simulation-chart-monthly"
                        );
                        if (url_base64jpMonth.childNodes.length > 0) {
                            forms.customerForm.simulationForm.annualSimulation.pictureMonth = null;
                            forms.customerForm.simulationForm.annualSimulation.pictureMonth = _.find(
                                url_base64jpMonth.childNodes,
                                (node) => node.localName === "canvas"
                            ).toDataURL("image/jpg");
                        }
                    },
                },
            },
        };
    },
    mounted() {
        if (this.form && this.form.headerSimulationForm) {
            updateSingleValues(this);
        }
    },
    methods: {
        getBillingReduction() {
            return (
                forms.customerForm.simulationForm.annualSimulation.saving
            );
        },
        onSubmit(evt) {
            evt.preventDefault();
        },
        onReset(evt) {
            evt.preventDefault();
        },
    },
};
</script>
