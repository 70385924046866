<template>
  <li class="user-identification-block">
    <div class="bloc_content">
      <h2>Identification de l'utilisateur</h2>
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group id="user-email-group" label="Email de l'utilisateur" class="bloc_form">
          <b-form-input
              v-model="forms.customerForm.userIdentificationForm.email"
              type="email"
              class="input_form"
              placeholder="Entrez l'email"
              required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="user-password-group" label="Mot de passe" class="bloc_form">
          <div class="input-group">
            <b-form-input
                v-model="forms.customerForm.userIdentificationForm.password"
                type="text"
                class="input_form"
                :placeholder="passwordPlaceholder"
                required
                style="border-radius: 18px;"
            ></b-form-input>
            <i class="fa fa-refresh input-icon" @click="generateRandomPassword" :class="{ spin: isSpinning }"></i>
          </div>
          <p v-if="showWarning" class="text-danger">
            Votre mot de passe ne pourra plus être accédé à l'avenir, enregistrez-le.
          </p>
        </b-form-group>
      </b-form>
    </div>
  </li>
</template>

<script>
export default {
  props: ['showWarning', 'forms'],
  data() {
    return {
      show: true,
      isSpinning: false,
      generatedPassword: ''
    };
  },
  computed: {
    passwordPlaceholder() {
      return this.forms.customerForm.customerIdentityForm.city ? 'Changement de mot de passe' : 'Entrez le mot de passe';
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
    },
    onReset(evt) {
      evt.preventDefault();
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    generateRandomPassword() {
      this.isSpinning = true;
      const length = 12;
      const charset = "abcdefghijkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ23456789~!@#$%^&*()-_=+[]{};:,./?";
      let retVal = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      this.generatedPassword = retVal;
      setTimeout(() => {
        this.isSpinning = false;
        this.forms.customerForm.userIdentificationForm.password = this.generatedPassword;
      }, 1000);
    }

  },
};
</script>
