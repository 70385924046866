const axios = require('axios');


axios.defaults.withCredentials = true;

const instance = axios.create();

instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('jwtToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        console.error('Error status:', error.response.status);
        return Promise.reject(error);
    }
);

export const axiosRequest = {
    postRequest: async (url, params) => {
        const response = await instance.post(url, params);
        return response.data;
    },
    getRequest: async (url) => {
        const response = await instance.get(url);
        return response.data;
    },
    putRequest: async (url, params) => {
        const response = await instance.put(url, params);
        return response.data;
    },
    deleteRequest: async (url, params) => {
        const response = await instance.delete(url, { data: params });
        return response.data;
    },
    uploadRequest: async (url, params) => {
        const response = await instance.put(url, params, { headers: {"Content-Type":"multipart/form-data"}});
        return response.data;
    }
};
