import { axiosRequest } from '../middlewares/axios'
import {conf} from '../config/config'

export const referentialService = {
  getAll,
}

async function getAll() {
  return await axiosRequest.getRequest(conf.API_URL + '/orientationReferentials/')
}
