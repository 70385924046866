<template>
  <b-form @submit="onSubmit" @reset="onReset" v-if="show">
	<div class="overlay_retix">
		<div class="container-fluid">
			
		<div class="row">
			<div class="col-12">
				<div class="container-retix">
					<div class="row">
						<div class="col-12">
							<div class="loader">
								<img src="../assets/images/loader.svg" border="0" alt="loader">
							</div>
						</div>
					</div>					
				</div>
			</div>
		</div>
			
		</div>
	</div>    
  </b-form>
</template>

<script>
export default {
  data() {
    return {
      show: true
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
    },
    onReset(evt) {
      evt.preventDefault();
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  }
};
</script>