import { referentialService } from '../../services/referential'

const namespaced = true

export default {
  namespaced,
  state: {
    all: {},
    actionState: null
  },
  getters: {
    getAll: (state:any) => {
      return state.all
    }
  },
  actions: {
    async getAll({ commit, state } : {commit:any, state:any}) {
      try {
        const referentials = await referentialService.getAll()
        commit('getAllSuccess', referentials)
      } catch (error) {
        commit('getAllFailure', error)
      }
    }
  },
  mutations: {
    getAllSuccess (state:any, referential:any) {
      state.all = referential.data
    },
    getAllFailure (state:any, error:any) {
      state.all = { error }
    }
  }
}