import util from "../utils/util"

export default {
    updateCustomerForm
}

export function updateCustomerForm(response: any, forms: any, id: number) {
    forms.id = id
    forms.customerForm.customerIdentityForm = response.customerIdentity
    forms.customerForm.electricalIdentityForm = response.electricalIdentity
    forms.customerForm.projectParametersForm = response.projectParameters
    forms.customerForm.projectIdentityForm = response.projectIdentity
    forms.customerForm.userIdentificationForm = response.userIdentification
    forms.simulationParametersSets = response.scenario.simulationParametersSets

    forms.customerForm.consumptionCurveForm = response.consumptionCurve

    forms.customerForm.simulationGoalsForm = response.simulationGoals

    forms.customerForm.scenariosForm = response.scenariosView
    forms.customerForm.simulationForm = response.simulation

    if (!forms.customerForm) {
        forms.customerForm = util.initCustomerForm(forms.customerForm)
    }
    
    if (!forms.customerForm.consumptionCurveForm) {
        forms.customerForm.consumptionCurveForm = util.initConsumptionCurveForm(forms.customerForm.consumptionCurveForm)
    }
    if (!forms.customerForm.simulationGoalsForm) {
        forms.customerForm.simulationGoalsForm = util.initSimulationGoalsForm(forms.customerForm.simulationGoalsForm)
    }

    if (!forms.customerForm.scenariosForm) {
        forms.customerForm.scenariosForm = util.initScenarioForm(forms.customerForm.scenariosForm)
    }

}
